import { configureStore } from "@reduxjs/toolkit"
import adminSiteSettingSlice from "./admin/siteSetting"
import adminPrivacyPolicySlice from "./admin/privacyPolicy"
import adminTermsAndConditionsSlice from "./admin/termsAndConditions"
import adminAboutSlice from "./admin/about"
import adminBannerSlice from "./admin/banner"
import adminServiceSlice from "./admin/service"
import adminQuestionAnswerSlice from "./admin/questionAnswer"
import adminContactSlice from "./admin/contact"
import adminProductSlice from './admin/product'
import adminProjectSlice from './admin/project'
import adminUserSlice from './admin/user'









import clientSiteSettingSlice from "./client/siteSetting"
import clientPrivacyPolicySlice from "./client/privacyPolicy"
import clientTermsAndConditionsSlice from "./client/termsAndConditions"
import clientAboutSlice from './client/about'
import clientBannerSlice from './client/banner';
import clientServiceSlice from './client/service';
import clientQuestionAnswerSlice from './client/questionAnswer';
import clientContactSlice from './client/contact';
import clientProductSlice from './client/product';
import clientProjectSlice from './client/project';




import authSlice from './client/auth';











const store = configureStore({
    reducer: {
        adminSiteSetting:adminSiteSettingSlice.reducer,
        adminPrivacyPolicy:adminPrivacyPolicySlice.reducer,
        adminTermsAndConditions:adminTermsAndConditionsSlice.reducer,
        adminAbout:adminAboutSlice.reducer,
        adminBanner:adminBannerSlice.reducer,
        adminService:adminServiceSlice.reducer,
        adminQuestionAnswer:adminQuestionAnswerSlice.reducer,
        adminContact:adminContactSlice.reducer,
        adminProduct:adminProductSlice.reducer,
        adminProject:adminProjectSlice.reducer,
        adminUser:adminUserSlice.reducer,
      



        clientSiteSetting:clientSiteSettingSlice.reducer,
        clientPrivacyPolicy:clientPrivacyPolicySlice.reducer,
        clientTermsAndConditions:clientTermsAndConditionsSlice.reducer,
        clientAbout:clientAboutSlice.reducer,
        clientBanner:clientBannerSlice.reducer,
        clientService:clientServiceSlice.reducer,
        clientQuestionAnswer:clientQuestionAnswerSlice.reducer,
        clientContact:adminContactSlice.reducer,
        clientProduct:clientProductSlice.reducer,
        clientProject:clientProjectSlice.reducer,



        auth:authSlice.reducer

    }
});

export default store;
export const adminSiteSettingActions = adminSiteSettingSlice.actions;
export const adminPrivacyPolicyActions = adminPrivacyPolicySlice.actions;
export const adminTermsAndConditionsActions = adminTermsAndConditionsSlice.actions;
export const adminAboutActions = adminAboutSlice.actions;
export const adminBannerActions = adminBannerSlice.actions;
export const adminServiceActions = adminServiceSlice.actions;
export const adminQuestionAnswerActions = adminQuestionAnswerSlice.actions;
export const adminContactActions = adminContactSlice.actions;
export const adminProductActions=adminProductSlice.actions;
export const adminProjectActions=adminProjectSlice.actions;
export const adminUserActions=adminUserSlice.actions;









export const clientSiteSettingActions = clientSiteSettingSlice.actions;
export const clientPrivacyPolicyActions = clientPrivacyPolicySlice.actions;
export const clientTermsAndConditionsActions = clientTermsAndConditionsSlice.actions;
export const clientAboutActions = clientAboutSlice.actions;
export const clientBannerActions = clientBannerSlice.actions;
export const clientServiceActions = clientServiceSlice.actions;
export const clientQuestionAnswerActions = clientQuestionAnswerSlice.actions;
export const clientContactActions = clientContactSlice.actions;
export const clientProductActions = clientProductSlice.actions;
export const clientProjectActions = clientProjectSlice.actions;


export const authActions=authSlice.actions;











