import React from "react";
import ItemsContainer from "./ItemsContainer";
import { FaBars, FaTiktok } from "react-icons/fa";
import{BsFacebook, BsInstagram, BsTwitter} from "react-icons/bs"
import { useSelector } from "react-redux";
import { useState } from "react";
// import SocialIcons from "./SocialIcons";
// import { Icons } from "./Menus";

const Footer = () => {
  const {siteSetting}=useSelector(state=>state.clientSiteSetting);
  const[sendTest,setSendText]=useState("Enter your queries here ")
  return (
    <footer className="   m-0 font-0">
      <div className="dark:bg-gray-800 md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#e9dede19] py-7">
        <h1
          className="lg:text-3xl  text-2xl md:text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold text-black
          dark:text-white flex-1
        "
        >
          <span className="text-teal-400 flex-1 text-2xl md:text-3xl ">WEAVE DECOR</span> we serve  you the best
        </h1>
        <div className="flex  flex-col gap-2 flex-1 max-w-sm
        ">
          <input
            type="text"
            value={sendTest}
            onChange={e=>{setSendText(e.target.value)}}
            placeholder="Enter Your Queries"
            className="text-gray-800
            w-full sm:mr-5 mr-0 lg:mb-0 mb-4 py-2.5 rounded-full px-2 focus:outline-none"
          />
          <button

          onClick={e=>{window.open(`mailto:${siteSetting?.siteEmail}?subject=subject&body=${sendTest}`)}}
            className="bg-blue-800 rounded-full hover:bg-teal-500 duration-300 px-5 py-2.5 font-[Poppins]
            text-white md:w-auto  "
          >
            send email 
          </button>
        </div>
      </div>
      <div className="bg-black text-white flex flex-col w-full justify-center items-center" >
       
      <ItemsContainer />
      <div
        className=" bg-black gap-2 w-full flex flex-col justify-center md:justify-between  p-4 text-sm md:flex md:flex-row"
      >
        <span className="text-center"> <a target={`_blank`} href="/terms">Terms</a>  | <a  target={`_blank`}href="/privacy">Privacy Policy</a> </span>

        <span className="text-center">© 2022 WEAVE DECOR. All rights reserved.</span>
    <div className="flex items-center justify-center gap-4   ">
      <a target={`_blank`} href={siteSetting?.siteFacebookProfileLink}>     <BsFacebook size={23} /></a>
      <a target={`_blank`} href={siteSetting?.siteTwitterProfileLink}>  <BsTwitter size={23}/></a>
      <a target={`_blank`} href={siteSetting?.siteTikTokProfileLink}>  <FaTiktok size={23}/></a>
      <a target={`_blank`} href={siteSetting?.siteInstagramProfileLink}>  <BsInstagram size={23}/></a>
    </div>
     
        {/* <SocialIcons Icons={Icons} /> */}
        {/* some contents are modified */}
      </div>
      </div>
      
    </footer>
  );
};

export default Footer;
