import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getBanner = createAsyncThunk(
  "banner/getAdminBanner",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/banner`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Banner fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch banner!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all banner!");
      
    }
  }
);

export const postBanner = createAsyncThunk(
  "banner/postAdminBanner",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/banner`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification("Banner Saved Sucessfully", {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save banner ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save banner!");
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanner.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.banner = action.payload;
        state.status = "idle";
      })
      .addCase(getBanner.rejected, (state, action) => {
        // state.banner=action.payload
        state.status = "failed";
      })
      .addCase(postBanner.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postBanner.fulfilled, (state, action) => {
        state.banner = action.payload;
        state.status = "idle";
      })
      .addCase(postBanner.rejected, (state, action) => {
        // state.banner=action.payload
        state.status = "failed";
      });
  },
});
export default bannerSlice;

// extraReducers: {
//     [getBanner.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.banner = action.payload;
//     },
//     [getBanner.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postBanner.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postBanner.fulfilled]: (state, action) => {
//         state.banners = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postBanner.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
