import React from 'react';
import { useState } from 'react';
import demoImage1 from '../../demoImages/demo1.jpg'
import demoImage11 from '../../demoImages/demo11.jpg'

import demoImage2 from '../../demoImages/demo2.jpg'

import demoImage3 from '../../demoImages/demo3.jpg'

import demoImage4 from '../../demoImages/demo4.jpg'

import demoImage5 from '../../demoImages/demo5.jpg'

import demoImage6 from '../../demoImages/demo6.jpg'

import demoImage7 from '../../demoImages/demo7.jpg'

import demoImage8 from '../../demoImages/demo8.jpg'
import demoImage9 from '../../demoImages/demo9.jpg'

import demoImage10 from '../../demoImages/demo10.jpg'
import demoImage14 from '../../demoImages/demo14.jpg'

import demoImage12 from '../../demoImages/demo12.jpg'

import demoImage13 from '../../demoImages/demo13.jpg'
import demoImage15 from '../../demoImages/banner1.jpg'

import demoImage16 from '../../demoImages/demo16.jpg'

import demoImage17 from '../../demoImages/demo17.jpg'





import {BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill,BsFullscreenExit} from 'react-icons/bs'
import {RxCrossCircled} from 'react-icons/rx'
const images =[
    {
        src:`${demoImage1}`,
        title:'test',
    },
    {
        src:`${demoImage2}`,
        title:'test2',
    },
    {
        src:`${demoImage3}`,
        title:'test3',
    },
    {
        src:`${demoImage4}`,
        title:'help',
    },
    {
        src:`${demoImage5}`,
        title:'test',
    },
    {
        src:`${demoImage6}`,
        title:'test2',
    },
    {
        src:`${demoImage7}`,
        title:'test3',
    },
    {
        src:`${demoImage8}`,
        title:'help',
    },
    {
        src:`${demoImage9}`,
        title:'test',
    },
    {
        src:`${demoImage10}`,
        title:'test2',
    },
    {
        src:`${demoImage11}`,
        title:'test3',
    },
    {
        src:`${demoImage12}`,
        title:'help',
    },
    {
        src:`${demoImage13}`,
        title:'test',
    },
    {
        src:`${demoImage14}`,
        title:'test2',
    },
    {
        src:`${demoImage15}`,
        title:'test3',
    },
    {
      src:`${demoImage16}`,
      title:'test3',
  },
  {
    src:`${demoImage17}`,
    title:'test3',
},
    
]

const ImageGallery = (props) => {
  const {images}=props;
    const [fullScreenImage, setFullScreenImage] = useState(null);
  
    const handleClick = (index) => {
      setFullScreenImage(index);
    };
  
    const handleClose = () => {
      setFullScreenImage(null);
    };
    const handlePrev = () => {
      if (fullScreenImage === 0) {
        setFullScreenImage(images.length - 1);
      } else {
        setFullScreenImage(fullScreenImage - 1);
      }
    };
    const handleNext = () => {
      if (fullScreenImage === images.length - 1) {
        setFullScreenImage(0);
      } else {
        setFullScreenImage(fullScreenImage + 1);
      }
    };
    //this is for arrow keys
    function handleKeyDown(event) {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
      else if (event.key === "Escape") 
      {
        handleClose();
      }
    }
    //this is brand new
   
  
      // window.addEventListener('keydown', handleKeyDown);
    
  
    return (
      <>
     {/* <div class="text-center  z-[9999] pt-5"> */}
            {/* <h1 class=" mb-1 mt-2 text-4xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white">Explore</h1> */}
            {/* </div> */}
        <div className=" grid grid-cols-1 gap-2 h-auto  xs:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 2xl:grid-cols-5">
          {images.map((image, index) => (
            <div
              className="bg-cover bg-center h-52 cursor-pointer"
              style={{ backgroundImage: `url(${image.src})` }}
              key={index}
              onClick={() => handleClick(index)}
            >
              <div className=" text-white text-center">
                <h3 className="text-md font-semibold">{image.title}</h3>
              </div>
            </div>
          ))}
        </div>
        {fullScreenImage !== null && (
        <div className="z-[999] fixed top-0 left-0 w-screen h-screen bg-black flex justify-center items-center" onKeyDown={handleKeyDown}>
          <img
          
            src={images[fullScreenImage].src}
            alt={images[fullScreenImage].title}
            className="object-contain h-auto"
            // onKeyDown={handleKeyDown}
          />
          <button
            className="fixed top-50 md:left-20 left-0 p-1 text-white  rounded-xl"
            onClick={handlePrev}
          >
            <BsFillArrowLeftCircleFill size={30}/>
          </button>
          <button
            className="fixed top-50 md:right-20 right-0 p-1 text-white  rounded-xl"
            onClick={handleNext}
          >
            <BsFillArrowRightCircleFill size={30}/>          </button>
          <button
            className="fixed top-10 right-0 p-2 text-white   rounded-xl"
            onClick={handleClose}
            
          >
            <RxCrossCircled size={30}/>
          </button>
         
        </div>
      )}
      </>
    );
  };
export default ImageGallery