
export const Items=[
    {
        "ind":"1",
        "item":"Home",
        "to":"/"
    },
    {
        "ind":"2",
        "item":"Services",
        "to":"/services",
        

        
    },
    {
        "ind":"3",
        "item":"Portfolio",
        "to":"/portfolio",
       

        
    },
    {
        "ind":"4",
        "item":"About",
        "to":"/about",
      

        
    },
    {
        "ind":"4",
        "item":"Products",
        "to":"/products"
    }

]
