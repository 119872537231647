import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import BannersTable from "../Tables/bannersTable";
import useCustomNotification from "../../../utils/notification";
import { getAbout, postAbout } from "../../../store/admin/about";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";
import { getBanner, postBanner } from "../../../store/admin/banner";

function Banner() {
  const notification = useCustomNotification();

  const dispatch = useDispatch();
  useEffect(() => {
    // setDataCkEditor(true);
    dispatch(getBanner({ notification }));
    //  notification("ddddd",{variant:"error"})
  }, []);
  const [imagePreview, setImagePreview] = useState(null);
  // const about={};
  // const status="dd"
  const { banner, status } = useSelector((state) => state.adminBanner);

  // const [editorLoaded, setEditorLoaded] = useState(false);
  const [dataCkEditor, setDataCkEditor] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const handleSubmitButton = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("_id", banner?._id);
    formData.append("bannerTitle", bannerTitle ? bannerTitle : "");
    formData.append("bannerImage", bannerImage ? bannerImage : "");
    formData.append("bannerDescription", dataCkEditor);
    dispatch(postBanner({ formData, notification }));
    console.log(dataCkEditor);
  };

  const bannerHandleChange = async (e) => {
    // console.log(e.target.name);
    if (e.target.name === "bannerImage") {
      const file = e.target.files[0];
      //  resizeFile(file).then(image=>{
      //    console.log(image);
      //    setAvatarPreview(image);
      //    setAvatar(image);
      //  });
      // console.log(file);
      if (
        file.type == "image/jpeg" ||
        file.type == "image/webp" ||
        file.type == "image/png"
      ) {
        // setSiteSettingData({...siteSettingData,siteLogo:file})
        setBannerImage(file);
        const url = URL.createObjectURL(file);
        setImagePreview(url);
      } else {
        setImagePreview(noImageLogo);
      }
    }
    if (e.target.name === "bannerTitle") {
      setBannerTitle(e.target.value);
    }
  };

  useEffect(() => {
    // setAboutTheme(about?.aboutTheme);
    setBannerTitle(banner?.bannerTitle);
    // console.log(banner);
    // setAboutImage(about?.aboutImage);
    setBannerImage(banner?.bannerImage);
    setImagePreview(banner?.bannerImage);

    if (banner && banner.bannerDescription) {
      setDataCkEditor(banner.bannerDescription);
    }
  }, [banner]);
  console.log(imagePreview);

  // console.log(siteSettingData);
  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }
  console.log(bannerTitle);
  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Banner
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="banner title"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Banner Title
            </label>
            <input
              type="text"
              name="bannerTitle"
              value={bannerTitle}
              onChange={bannerHandleChange}
              id="bannertitle"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="banner title"
              required
            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1">
              <label
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                for="file_input"
              >
                Upload Banner Image
              </label>
              <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="file_input"
                type="file"
                name="bannerImage"
                // value={siteSettingData?.siteLogo?siteSettingData.siteLogo:""}
                onChange={bannerHandleChange}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                SVG, PNG, JPG (MAX. 56x56).
              </p>
            </div>
            <div className="h-14 w-14 flex items-center">
              <img
                src={imagePreview ? imagePreview : ""}
                className="object-contain"
                alt="siteLogo"
              />
            </div>
          </div>

          <div className="w-11/12">
            <CKeditor
              name="Banner Description"
              content={dataCkEditor}
              setContent={setDataCkEditor}
              initialHeight="100px"
              // editorLoaded={editorLoaded}
            />
            {/* {JSON.stringify(dataCkEditor)} */}
          </div>

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* table */}
      {/* <BannersTable /> */}
    </div>
  );
}

export default Banner;
