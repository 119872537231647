import React, { useRef, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const editorConfig = {
   
  };
  

function CKeditor(props) {

const {content,setContent,name,initialHeight}=props;
  const editorRef=useRef();
    function handleEditorChange(event, editor) {
      const data = editor.getData();
      setContent(data);
    }
  
    
    return (
      <div  >
       <label
              htmlFor={name}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {name}
            </label>
        <CKEditor
          editor={ClassicEditor}
        
          data={content}
          onChange={handleEditorChange}

          onReady={editor => {
            editor.editing.view.change(writer => {
                writer.setStyle('min-height',`${initialHeight?initialHeight:"100px"}`, editor.editing.view.document.getRoot());
               // you can add style here whatever you want for example:
                   
              //  writer.setStyle('background-color','red',editor.editing.view.document.getRoot());
                writer.setStyle('z-index','999999 !important',editor.editing.view.document.getRoot());

            });
        }}

        />
       </div>
    
    );
  }

  export default CKeditor;
  