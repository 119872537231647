import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getTermsAndConditions = createAsyncThunk(
  "termsAndConditions/getAdminTermsAndConditions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/termsandconditions`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Terms and Conditions fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch terms and conditionss!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all terms and conditions!");
      
    }
  }
);

export const postTermsAndConditions = createAsyncThunk(
  "termsAndConditions/postAdminTermsAndConditions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/termsandconditions`,
        params.formData
      );
      // params.resetForm();
      // console.log(response);
      if(response.data.sucess){
        params.notification("Terms and Conditions Saved Sucessfully", {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save terms and conditions", {
        variant: "error",
      });
      return rejectWithValue("Failed to save Terms and Conditions!");
    }
  }
);

const termsAndConditionsSlice = createSlice({
  name: "termsAndConditions",
  initialState: {
    termsAndConditions: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTermsAndConditions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndConditions = action.payload;
        state.status = "idle";
      })
      .addCase(getTermsAndConditions.rejected, (state, action) => {
        // state.termsAndConditions=action.payload
        state.status = "failed";
      })
      .addCase(postTermsAndConditions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndConditions = action.payload;
        state.status = "idle";
      })
      .addCase(postTermsAndConditions.rejected, (state, action) => {
        // state.termsAndConditions=action.payload
        state.status = "failed";
      });
  },
});
export default termsAndConditionsSlice;

// extraReducers: {
//     [getTermsAndConditions.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.termsAndConditions = action.payload;
//     },
//     [getTermsAndConditions.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch terms settings!" })
//     },
//     [postTermsAndConditions.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postTermsAndConditions.fulfilled]: (state, action) => {
//         state.termsAndConditionss = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postTermsAndConditions.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
