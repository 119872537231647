import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import portfolioDemoImage from "../../demoImages/banner1.jpg";
import { getProject } from "../../store/client/project";
import ImageGallery from "../home/ImageGallery";

function ProjectDetails(props) {
  //   const portfolioData = props.portfolioData;
  const {projectId}=useParams();
  console.log(projectId);
  const[selectedProject,setSelectedProject]=useState(null);
  const[selectedProjectImages,setSelectedProjectImages]=useState(null);

  const {project}=useSelector(state=>state.clientProject);
const dispatch=useDispatch();

  useEffect(()=>{

dispatch(getProject());



  },[])

  useEffect(()=>{
    let data=[]
  project?.map(proj=>{
    if(proj._id==projectId){
      setSelectedProject(proj);
      proj.projectImages.map(url=>{
        data.push({
          src:url,
          title:""
        })
      })
    }
  })
  setSelectedProjectImages(data);
  console.log(data);
  },[project])

  return (
    <div>
      {/* <h1 className="text-3xl font-bold text-center">Our Portfolio</h1> */}
      <div className="p-4 relative min-h-full">
        <div className="  text-black dark:bg-gray-900 dark:text-white flex gap-2 flex-col justify-center items-start sm:flex-row">
          <div className="w-full md:w-2/4  ">
            <img
              src={selectedProject?.projectImages[0]}
              alt={""}
              className=" object-contain"
            />
          </div>

          <div className="w-full md:w-2/4 flex gap-3 flex-col p-0 pt-4 md:p-4 ">
            <h1 className="max-w-2xl mb-2 text-2xl font-bold tracking-tight leading-none md:text-1xl xl:text-2xl dark:text-white">
            {selectedProject?.projectTitle}
            </h1>
            <p class="max-w-2xl mb-2  font-semibold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
              Project Category
              <p className="font-light text-gray-800 dark:text-white">
              {selectedProject?.projectCategory}
              </p>
            </p>
            <p class="max-w-2xl mb-2  font-semibold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
              Location
              <p className="font-light text-gray-800 dark:text-white">
              {selectedProject?.projectAddress}
              </p>
            </p>
            <p class="max-w-2xl mb-2  font-semibold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
              Project Duration
              <p className="font-light text-gray-800 dark:text-white">
              {selectedProject?.projectDuration}
              </p>
            </p>
            <p class="max-w-2xl mb-2  font-semibold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
              Total Area
              <p className="font-light text-gray-800 dark:text-white">
              {selectedProject?.projectTotalArea}
              </p>
            </p>
           
          </div>
        </div>
{/* //description part */}
        <div className="w-full mt-4">
        <p class="w-full mb-2  font-bold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
              Project Description
              <p 
               dangerouslySetInnerHTML={{__html:selectedProject?.projectDescription }}
              className="font-normal text-black dark:text-white text-justify">
            
                
              </p>
            </p>
        </div>

        {/* more productImages part
         */}
           <div className="  p-1">
      <h1 className="max-w-2xl mb-2 text-2xl font-bold tracking-tight leading-none md:text-1xl xl:text-2xl dark:text-white">
              Project Images
            </h1>
        <ImageGallery images={selectedProjectImages?selectedProjectImages:[]} />
        <div className="mt-4 flex justify-center items-center">
          <Link
            to={`/portfolio`}
            class="inline-flex items-center dark:border-gray-500 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-400 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:hover:bg-gray-800 dark:focus:ring-gray-800 "
          >
            Explore More Projects
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
