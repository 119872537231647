import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import Acc from "../components/pages/Services/Acc";
import useCustomNotification from "../utils/notification";
import { useDispatch, useSelector } from "react-redux";
import { postContact } from "../store/client/contact";
import LoadingSpinner from "../components/LoadingSpinner/loadingSpinner";

const siteData = {
  title: "Weave-Decor",
  info: [
    {
      title: "kanlanki branch",
      subtitle:
        "Welcome to Weaesign firm specializing in areas of expertise, such as residential or commercial architecture, interior design, historic preservation, sustainability, etc. With a passion for excellence and a commitment to exceptional client service, our team of skilled architects and designers is dedicated to bringing your vision to life.",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "kalanki-4 ,malkalu",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+9779867550355",
      },
      email: {
        icon: <FaEnvelope />,
        address: "weave@gmail.com",
      },
      link: "Get location",
    },
    // {
    //   title: 'sankhu office',
    //   subtitle:
    //     'Sit amet, consectetur nun in velit arcu posuere integer. Adipiscing elit duis porttitor massa tellus',
    //   address: {
    //     icon: <FaMapMarkerAlt />,
    //     name: 'sali river ,sankhu',
    //   },
    //   phone: {
    //     icon: <FaPhoneAlt />,
    //     number: '+977 987654321',
    //   },
    //   email: {
    //     icon: <FaEnvelope />,
    //     address: 'weaver-decor@gmail.com',
    //   },
    //   link: 'Get location',
    // },
  ],
  form: {
    name: "Write your name here",
    email: "Write your email address",
    message: "Write your messages here",
    btnText: "Send it",
  },
};
const accordions = [
  {
    id: 1,
    title: "What is  Weave-decor?",
  },
  {
    id: 2,
    title: "What are the services?",
  },
  {
    id: 3,
    title: "what are the things?",
  },
  {
    id: 4,
    title: "Why is ayush so handsome?",
  },
];

const ContactUs = () => {
  const { about } = useSelector((state) => state.clientAbout);
  const { siteSetting } = useSelector((state) => state.clientSiteSetting);
  const { questionAnswer } = useSelector((state) => state.clientQuestionAnswer);

  const notification = useCustomNotification();
  const dispatch = useDispatch();

  const { status, contact } = useSelector((state) => state.clientContact);
  // destructure contact data
  const { title, info, form } = siteData;
  //for form data
  const [contactData, setContactData] = useState({
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    contactSubject: "",
    contactMessage: "",
  });

  const contactHandleChange = async (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setContactData({
      contactName: "",
      contactEmail: "",
      contactNumber: "",
      contactSubject: "",
      contactMessage: "",
    });
  };
  const onHandleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("_id", "undefined");
    formData.append("contactName", contactData.contactName);
    formData.append("contactEmail", contactData.contactEmail);
    formData.append("contactSubject", contactData.contactSubject);
    formData.append("contactNumber", contactData.contactNumber);
    formData.append("contactMessage", contactData.contactMessage);
    dispatch(postContact({ formData, notification, resetForm }));
  };

  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (
    <div className="p-4">
      <section className="section ">
        <div className="">
          <div className="flex flex-col lg:flex-row ">
            <motion.div
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.4 }}
              className="flex-1 "
            >
              {/* title */}
              <h1 class=" mb-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-start">
                {siteSetting?.siteName}
              </h1>
              {/* //image about company */}

              {/* info items */}
              <div className="flex flex-col xl:flex-row gap-x-5 gap-y-16 xl:gap-y-0">
                <div>
                  {/* title */}
                  {/* <div className='font-bond uppercase font-medium text-xl mb-3 dark:text-white'>
                      {title}
                    </div> */}
                  {/* subtitle */}
                  <div className="mb-6 text-[#333] text-justify leading-[187%] tracking-[0.02em] dark:text-white">
                    {about?.aboutDetails}
                  </div>
                  {/* //image */}
                  <div class="mb-4 lg:mt-0 lg:col-span-5 lg:flex">
                    <img src={about?.aboutImage} alt="about image" />
                  </div>
                  {/* address, phone & email */}
                  <div className="flex flex-col gap-y-3 mb-8">
                    {/* address */}
                    <div className="flex items-center gap-[10px]">
                      <div>
                        <FaMapMarkerAlt />
                      </div>
                      <div className="font-medium">
                        {siteSetting?.siteAddress}
                      </div>
                    </div>
                    {/* phone */}
                    <div className="flex items-center gap-[10px]">
                      <div>
                        <FaPhoneAlt />
                      </div>
                      <div className="font-medium">
                        {siteSetting?.siteContactNumber}
                      </div>
                    </div>
                    {/* email */}
                    <div className="flex items-center gap-[10px]">
                      <div>
                        <FaEnvelope />
                      </div>
                      <div className="font-medium">
                        {siteSetting?.siteEmail}
                      </div>
                    </div>
                  </div>
                  {/* link */}
                  <a
                    className="font-medium border-b border-dark pb-[5px]"
                    href="#"
                  >
                    {/* {link} */}
                  </a>
                </div>
              </div>
            </motion.div>
            {/* form */}

            <section class="bg-white dark:bg-gray-900">
              <div class="  px-4  w-full">
                <h1 class=" mb-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-start">
                  Weave Decors
                </h1>
                <p class="font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                  How can we help you? Send us email or contact us with
                  following form
                </p>
                <form
                  action="#"
                  onSubmit={onHandleSubmit}
                  class="space-y-4 w-full xl:w-3/4"
                >
                  <div>
                    <label
                      for="subject"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-gray-300"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="contactName"
                      value={contactData.contactName}
                      onChange={contactHandleChange}
                      class="block p-3 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="name"
                      required={true}
                    />
                  </div>
                  <div>
                    <label
                      for="email"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-gray-300"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="contactEmail"
                      value={contactData.contactEmail}
                      onChange={contactHandleChange}
                      class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="dan@gmail.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Contact number
                    </label>
                    <input
                      type="number"
                      name="contactNumber"
                      id="contactNumber"
                      placeholder="9812345678"
                      required
                      value={contactData.contactNumber}
                      onChange={contactHandleChange}
                      min="0"
                      onInput={(e) => {
                        e.target.validity.valid || (e.target.value = "");
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      //required
                    />
                  </div>
                  <div>
                    <label
                      for="subject"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-gray-300"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="contactSubject"
                      value={contactData.contactSubject}
                      onChange={contactHandleChange}
                      class="block p-3 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="subject name"
                      required
                    />
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="message"
                      class="block mb-2 text-md font-medium text-gray-900 dark:text-gray-400"
                    >
                      Your message
                    </label>
                    <textarea
                      id="message"
                      name="contactMessage"
                      value={contactData.contactMessage}
                      onChange={contactHandleChange}
                      required
                      rows="6"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Leave a comment..."
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    // onClick={onHandleSubmit}
                    class="py-3 mt-6 px-5 text-md font-medium text-center text-white rounded-lg bg-gray-900 sm:w-fit hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-red-800"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </section>

            {/* text */}
          </div>
          <div className="mt-12  p-1 w-full ">
            {questionAnswer?.map((item) => {
              if (item.questionType === "ask") {
                return (
                  <div className="">
                    <Acc
                      key={item._id}
                      title={item.question}
                      answer={item.answer}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
