import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getAbout = createAsyncThunk(
    "about/getClientAbout",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/about`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all about!");
        }
    })

const aboutSlice = createSlice({
    name: "about",
    initialState: {
        about:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getAbout.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getAbout.fulfilled, (state, action) => {
            state.about=action.payload
            state.status = 'idle'
          }) .addCase(getAbout.rejected, (state, action) => {
            // state.about=action.payload
            state.status = 'failed'
          })
      }
    
})
export default aboutSlice;

// extraReducers: {
//     [getAbout.fulfilled]: (state, { payload }) => {
//         state.about = payload;
//     },
// }