import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../store/client/termsAndConditions";

function Terms() {
  const {termsAndConditions}=useSelector(state=>state.clientTermsAndConditions);

  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(getTermsAndConditions());
// dispatch(getSiteSetting());


  },[])
  return (
    // <div className="max-w-2xl mx-auto py-12">
    //   <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
    //   <div className="space-y-6">
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Introduction</h2>
    //       <p>Welcome to our website. By accessing or using our website, you agree to be bound by these Terms and Conditions, our Privacy Policy, and all applicable laws and regulations.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Use of Website</h2>
    //       <p>You may use our website only for lawful purposes and in accordance with these Terms and Conditions. You may not use our website in any way that violates any applicable federal, state, local, or international law or regulation.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Intellectual Property</h2>
    //       <p>The content, features, and functionality of our website, including but not limited to text, graphics, logos, images, and software, are the property of our company or its licensors and are protected by intellectual property laws.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Limitations of Liability</h2>
    //       <p>We are not liable for any damages, including but not limited to direct, indirect, incidental, consequential, or punitive damages, arising from your use or inability to use our website or any content on our website.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Links to Third-Party Websites</h2>
    //       <p>Our website may contain links to third-party websites or resources. We are not responsible for the content, accuracy, or opinions expressed in any third-party websites or resources.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Termination</h2>
    //       <p>We reserve the right to terminate or suspend your access to all or any part of our website for any reason, without notice or liability.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Governing Law</h2>
    //       <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Nepal.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Changes to Terms and Conditions</h2>
    //       <p>We may update these Terms and Conditions from time to time. We encourage you to review these Terms and Conditions periodically.</p>
    //     </div>
    //     <div>
    //       <h2 className="text-xl font-bold mb-2">Contact Us</h2>
    //       <p>If you have any questions or concerns about these Terms and Conditions, please contact us at weave@gmail.com.</p>
    //     </div>
    //   </div>
    // </div>
<div className="ml-5 mr-5 py-4">
<div dangerouslySetInnerHTML={{__html: termsAndConditions?.details}} />

</div>
  );
}

export default Terms;
