// import icons
import {
    
    IoMdAddCircle,
    IoIosCheckmarkCircle,
    IoIosArrowRoundForward,
  } from 'react-icons/io';

  

function ServiceList(props) {
    const{title,description}=props;
    // console.log(description);
    return ( <div>
          <div className='flex mb-6 lg:last:mb-0' >
                    <div className='text-2xl lg:text-3xl mr-4 mt-1'><IoIosCheckmarkCircle size={24}/></div>
                    <div className='mb-0 sm:mb-4'>
                      <h4 className='text-base lg:text-xl font-semibold '>
                        {title}
                      </h4>
                      <p    dangerouslySetInnerHTML={{__html: description}}></p>
                    </div>
                  </div>
    </div> );
}

export default ServiceList;