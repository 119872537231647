import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject, getProject } from "../../../store/admin/project";
import useCustomNotification from "../../../utils/notification";
import ConfrimationModal from "../../confirmationModal/confirmationModal";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";
import ProjectsTable from "../Tables/projectsTable";


function ViewProjects() {
    const notification = useCustomNotification();
    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(getProject({notification}))
  
    },[])

    const [projectDeleteId,setProjectDeleteId]=useState(undefined);

    const {status,project}=useSelector(state=>state.adminProject);
 
  
    const [deleteModalState, setDeleteModalState] = useState(false);
  
    const onHandleButtonClick = (id) => {
      setDeleteModalState(true);
      setProjectDeleteId(id)
      console.log("dd");
    };

    const onHandleEditClick=()=>{

    }
  
    const confirmDelete = () => {
      console.log("delete confirm");
      const formData=new FormData();
      formData.append('_id',projectDeleteId);
  
      dispatch(deleteProject({formData,notification}));
      setProjectDeleteId(undefined)
      setDeleteModalState(false);
    };
    const cancelDelete = () => {
      console.log("delete cancel");
      setDeleteModalState(false);
    };

    useEffect(() => {}, [deleteModalState,project]);

    if (status == "loading") {
        return (
          <>
            <LoadingSpinner />
          </>
        );
      }
    return ( <div>
        <ProjectsTable
        list={project}
        handleEdit={onHandleEditClick}
        handleDelete={onHandleButtonClick}

        />
        {/* confirmation delete  */}

      {deleteModalState && (
        <ConfrimationModal
          handleConfirm={confirmDelete}
          handleCancel={cancelDelete}
          confirmationMessage={"Are you Sure?Do You want to delete this project?"}
        />
      )}
    </div> );
}

export default ViewProjects;