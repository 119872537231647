import React from "react";
import { useSelector } from "react-redux";
import FAQ from "./FAQ";
const data = [
  {
    question: "What is interior design?",
    ans: "Interior design is the art and science of enhancing the interior of a space to achieve a healthier and more aesthetically pleasing environment for the people using the space.",
  },
  {
    question:
      "What is the difference between interior design and interior decorating?",
    ans: "Interior design involves the planning and design of the interior space, including architectural details, lighting, and layout, while interior decorating is focused on selecting and arranging furniture, accessories, and decorative elements to create a specific style or mood in a space.",
  },
  {
    question: "What are some popular interior design styles?",
    ans: "Some popular interior design styles include modern, contemporary, traditional, transitional, and mid-century modern.",
  },
  {
    question: "What are some key elements of interior design?",
    ans: "Key elements of interior design include space planning, color, lighting, texture, and pattern.",
  },
  {
    question: "Why is lighting important in interior design?",
    ans: "Lighting is an essential element of interior design as it can dramatically impact the mood and functionality of a space. Good lighting can highlight important features, create a warm and inviting atmosphere, and even affect our health and well-being.",
  },
  {
    question: "How do I choose the right color scheme for my space?",
    ans: "When choosing a color scheme for your space, consider the mood you want to create and the function of the space. You can use color to create a sense of calm or energy, and different colors can evoke different emotions. It's also important to consider the existing colors in your space, such as flooring and furniture, and choose colors that complement or contrast them in a pleasing way.",
  },
];
const datas = [
  {
    question: "How do I choose the right furniture for my space?",
    ans: "When choosing furniture, consider the size and layout of your space, as well as the function of the room. Look for furniture that is the right scale for your space and fits your style and budget. It's also important to think about how you'll be using the space and choose furniture that is both comfortable and functional.",
  },
  {
    question: "What is the best flooring for a high-traffic area?",
    ans: "For high-traffic areas, it's important to choose a flooring material that is durable and easy to clean. Options such as hardwood, tile, and vinyl are popular choices for high-traffic areas as they can withstand wear and tear over time.",
  },
  {
    question: "How do I create a focal point in my room?",
    ans: "Creating a focal point is a great way to add interest and draw the eye into a room. This can be done by adding a statement piece of furniture, such as a bold-colored sofa or unique piece of artwork, or by emphasizing an existing feature such as a fireplace or large window.",
  },
  {
    question: "What is the rule of three in interior design?",
    ans: "The rule of three is a common principle in interior design that suggests that items grouped in threes are more visually appealing than other groupings. This could be applied to the placement of accessories on a table, or the grouping of wall art or pendant lights.",
  },
  // {
  //   question: "How can I make a small room look larger?",
  //   ans: "There are several ways to make a small room appear larger, such as choosing light and neutral colors for walls and furniture, using mirrors to create the illusion of more space, and incorporating multi-functional furniture such as a sofa bed or storage ottoman."
  // },
  // {
  //   question: "What are some ways to incorporate color into my space?",
  //   ans: "Color can be added to a space in a variety of ways, such as through wall paint or wallpaper, furniture upholstery, decorative accessories, and even lighting. It's important to consider the mood you want to create and choose colors that work well together and complement the overall style of the room."
  // },
  // {
  //   question: "What is the best lighting for a bedroom?",
  //   ans: "The best lighting for a bedroom should create a warm and relaxing atmosphere. This can be achieved through the use of table lamps, floor lamps, and dimmer switches to control the amount of light in the space. It's also important to consider task lighting for reading or getting dressed, and to avoid harsh overhead lighting that can be too bright and jarring."
  // },
  {
    question: "How do I mix patterns in my decor?",
    ans: "Mixing patterns can add interest and depth to a space, but it's important to do so in a thoughtful way. Start by choosing a dominant pattern and then layer in additional patterns that complement it in terms of color and scale. It's also important to vary the texture and scale of patterns to keep things visually interesting.",
  },
  {
    question: "What is the best way to arrange furniture in a living room?",
    ans: "When arranging furniture in a living room, it's important to consider the size and layout of the space, as well as the function of the room. Start by creating a focal point, such as a fireplace or TV, and then arrange furniture around it to create conversation areas. Be sure to leave enough space for traffic flow and consider the placement of lighting and accessories to complete the look.",
  },
];

const TestFAQ = () => {
  const { questionAnswer } = useSelector((state) => state.clientQuestionAnswer);

  return (
    <div>
      <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4  w-full sm:py-6 lg:px-6">
          <h1 class="mt-2 mb-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-start">
            Frequently Asked Questions
          </h1>
          <div class="grid  pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
           
              {questionAnswer?.map((item, index) => {
                if (item.questionType === "info") {
                  return (
                    <div key={item._id} className="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg
                          class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        {item.question}
                      </h3>
                      <p 
                       dangerouslySetInnerHTML={{__html: item.answer}}
                      class="text-gray-500 dark:text-gray-400 text-justify ml-3">
                      
                      </p>
                    </div>
                  );
                }
              })}
         
            {/* <div>
              {datas.map((faq, index) => (
                <div key={index} className="mb-10">
                  <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                    <svg
                      class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {faq.question}
                  </h3>
                  <p class="text-gray-500 dark:text-gray-400 text-justify ml-3">
                    {faq.ans}
                  </p>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TestFAQ;
