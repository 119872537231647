import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import { data } from "autoprefixer";
import MultipleImageUpload from "../../multipleImageUpload/multipleImageUpload";
// import demoProductImage from "../../demoImages/productDemoImage1.jpg";
import MultiSelect from "react-multiple-select-dropdown-lite";

import "react-multiple-select-dropdown-lite/dist/index.css";
import "../../../styles/global.css";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@material-tailwind/react";
import useCustomNotification from "../../../utils/notification";
import { getProject, postProject } from "../../../store/admin/project";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";

function CreateProject() {
  const notification = useCustomNotification();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProject({ notification }));
  }, []);
  const { status, product } = useSelector((state) => state.adminProject);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [startDuration, setStartDuration] = useState("");
  const [endDuration, setEndDuration] = useState("");

  const [totalArea, setTotalArea] = useState("");
  const [address, setAddress] = useState("");

  const [selectedProductId, setSelectedProductId] = useState("undefined");
  //   for multiple image upload
  const [uploadImages, setUploadImages] = useState([]);
  const [dataCkEditor, setDataCkEditor] = useState("");

  const handleOnchange = (val) => {
    setCategory(val);
    // console.log(vategory);
  };

  const options = [
    { label: "Design", value: "Design" },
    { label: "Building", value: "Building" },
    { label: "Kitchen", value: "Kitchen" },
    { label: "Architect", value: "Architect" },
  ];

  const resetForm = () => {
    setName("");
    setCategory("");
    setAddress("");
    setStartDuration("");
    setEndDuration("");
    setTotalArea("");
    setUploadImages([]);
    setDataCkEditor("");
  };

  const handleSubmitButton = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("_id", selectedProductId);
    formData.append("projectName", name);
    formData.append("projectCategory", category);
    formData.append("projectDuration", `${startDuration} to ${endDuration}`);
    formData.append("projectTotalArea", totalArea);
    formData.append("projectAddress", address);

    formData.append("projectDescription", dataCkEditor);
    console.log(uploadImages);
    for (let i = 0; i < uploadImages.length; i++) {
      // const element = uploadImages[i];
      formData.append("projectImages", uploadImages[i]);
    }
    dispatch(postProject({ formData, resetForm, notification }));
  };

  useEffect(() => {
    // setDataCkEditor(true);
  }, []);

  // // this is just for test
  // const changeDescriptionFieldNumber = (changeValue) => {
  //   setDescriptionFieldNo(descriptionFieldNo + changeValue);
  //   if (changeValue == 1) {
  //     productDescriptionArray.push(descriptionFieldNo);
  //   } else {
  //     productDescriptionArray.pop();
  //   }

  //   console.log("increse", descriptionFieldNo, productDescriptionArray);
  // };

  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Create Project
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Project Name
            </label>
            <input
              type="text"
              name="text"
              id="projectName"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="project name"
              required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Product Category
            </label>
            <MultiSelect
              className="multi-select"
              onChange={handleOnchange}
              options={options}
              customValue={true}
            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="duration"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Project Duration
            </label>

            <div date-rangepicker class="flex items-center">
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="startDate"
                  type="text"
                  value={startDuration}
                  onChange={e=>{setStartDuration(e.target.value)}}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date start"
                />
              </div>
              <span class="mx-4 text-gray-500">to</span>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="conpletedDate"
                  value={endDuration}
                  onChange={e=>{setEndDuration(e.target.value)}}
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select Completed Date"
                />
              </div>
            </div>
          </div>

          <div className="w-11/12">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={address}
              onChange={e=>{setAddress(e.target.value)}}
              placeholder="kupondole,Lalitpur"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="totalArea"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Total Area
            </label>
            <input
              type="text"
              name="totalArea"
              id="totalArea"
              value={totalArea}
              onChange={e=>{setTotalArea(e.target.value)}}
              placeholder="total area eg. 5000 sq.ft"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
            />
          </div>

          {/* <div className="w-11/12">
            <label
              htmlFor="totalArea"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Project Status
            </label>
            <input
              type="text"
              name="pro"
              id="totalArea"
              placeholder="total area eg. 5000 sq.ft"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
            />
          </div> */}

          <div className="w-11/12 ">
            <CKeditor
              name="Project Description"
              content={dataCkEditor}
              setContent={setDataCkEditor}
              // editorLoaded={editorLoaded}
            />
            {/* {JSON.stringify(dataCkEditor)} */}
          </div>

          <div className="w-11/12">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Upload Project Imgages
            </label>
            <MultipleImageUpload images={uploadImages} setImages={setUploadImages} />
          </div>

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProject;
