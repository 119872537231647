import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import { data } from "autoprefixer";
import QuestionAnswer from "./questionAnswer";
// import demoProductImage from "../../demoImages/productDemoImage1.jpg";
import useCustomNotification from "../../../utils/notification";
import { getAbout, postAbout } from "../../../store/admin/about";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";

const categoryList = [
  { value: "Electronics", label: "Electronics" },
  { value: "Cameras", label: "Cameras" },
  { value: "Laptops", label: "Laptops" },
  { value: "Accessories", label: "Accessories" },
  { value: "Headphones", label: "Headphones" },
  { value: "Food", label: "Food" },
  { value: "Books", label: "Books" },
  { value: "Clothes/Shoes", label: "clothes/Shooes" },
  { value: "Beauty/Health", label: "Beauty/Health" },
  { value: "Sports", label: "Sports" },
  { value: "Outdoor", label: "Outdoor" },
  { value: "Home", label: "Home" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "w-full",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

function About() {
  const notification =useCustomNotification()

const dispatch=useDispatch();
useEffect(()=>{
  // setDataCkEditor(true);
  dispatch(getAbout({notification}));
//  notification("ddddd",{variant:"error"})
},[]);
const[imagePreview,setImagePreview]=useState(null);
// const about={};
// const status="dd"
const {about,status}=useSelector(state=>state.adminAbout);



  // const [editorLoaded, setEditorLoaded] = useState(false);
  const [dataCkEditor, setDataCkEditor] = useState("");
  const [aboutTheme,setAboutTheme]=useState("");
  const [aboutImage,setAboutImage]=useState("");
  

  const handleSubmitButton=(e)=>{
    e.preventDefault();
    const formData=new FormData();
    formData.append('_id',about?._id);
    formData.append('aboutTheme',aboutTheme?aboutTheme:'');
    formData.append('aboutImage',aboutImage?aboutImage:"");
    formData.append('aboutDescription',dataCkEditor);
    dispatch(postAbout({formData,notification}))
    console.log(dataCkEditor);
  }


  const aboutHandleChange = async(e) => {
    // console.log(e.target.name);
    if (e.target.name === "aboutImage") {
      const file = e.target.files[0];
      //  resizeFile(file).then(image=>{
      //    console.log(image);
      //    setAvatarPreview(image);
      //    setAvatar(image);
      //  });
      // console.log(file);
      if(file.type=="image/jpeg"||file.type=="image/webp" || file.type=="image/png" ){
        // setSiteSettingData({...siteSettingData,siteLogo:file})
        setAboutImage(file);
        const url=URL.createObjectURL(file);
        setImagePreview(url);
      }else{
        setImagePreview(noImageLogo);
      }

      
      
    } 
    if(e.target.name==="aboutTheme"){
      setAboutTheme(e.target.value);
    }
  };


  useEffect(()=>{
    setAboutTheme(about?.aboutTheme);
    setAboutImage(about?.aboutImage);
    setImagePreview(about?.aboutImage)
    
    if(about && about.aboutDescription){
      setDataCkEditor(about.aboutDescription)
    }
      },[about])
    
    // console.log(siteSettingData);
     if(status=="loading")
    {
      return(<>
      <LoadingSpinner/>
      </>)
    }
  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
           About Us
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="theme"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              About Theme
            </label>
            <input
              type="text"
              name="aboutTheme"
              id="aboutTheme"
              value={aboutTheme}
              onChange={aboutHandleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="site theme"
              required
            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1">
              <label
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                for="file_input"
              >
                Upload About Image
              </label>
              <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="file_input"
                type="file"
                name="aboutImage"
                // value={siteSettingData?.siteLogo?siteSettingData.siteLogo:""}
                onChange={aboutHandleChange}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                SVG, PNG, JPG  (MAX. 56x56).
              </p>
            </div>
            <div className="h-14 w-14 flex items-center">
              <img 
              src={ imagePreview?imagePreview:""}
              className="object-contain"
               alt="siteLogo" />
            </div>
          </div>

         
          <div className="w-11/12 "  >
           
              <CKeditor
              name="About Description"
               
              content={dataCkEditor}
                setContent={setDataCkEditor}
                // editorLoaded={editorLoaded}
              />
              {/* {JSON.stringify(dataCkEditor)} */}
           
          </div>

          

        

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* question QuestionAnswer */}
      <QuestionAnswer/>
    </div>
  );
}

export default About;
