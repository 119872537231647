import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SocialLogin from "../../SocialLogin/socialLogin";
import { postUser } from "../../store/client/auth";
import useCustomNotification from "../../utils/notification";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";

function SignUp() {
  const notification=useCustomNotification();
  const dispatch=useDispatch();

  const {status,auth}=useSelector(state=>state.auth);
  // destructure user data
  // const { title, info, form } = siteData;
//for form data
const [userData,setUserData] = useState({
  name:"",
  email:"",
  password:""
  
});

const userHandleChange = async(e) => {
  
    setUserData({ ...userData, [e.target.name]: e.target.value });
  
};

const resetForm=()=>{
  setUserData({
    name:"",
    email:"",
    password:""
    
  });
}
const onHandleSubmit=(e)=>{
e.preventDefault();
const formData=new FormData();
formData.append('name',userData.name);
formData.append('email',userData.email);
formData.append('password',userData.password);

dispatch(postUser({formData,notification,resetForm}))

}


if(status=="loading")
{
  return(<>
  <LoadingSpinner/>
  </>)
}



    return (   <div className="flex justify-center mt-0 pt-10">
    <div className="p-4 w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
         <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create and account
              </h1>
              <form className="space-y-4 md:space-y-2" action="submit" onSubmit={onHandleSubmit}>
              <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                  <input 
                  type="name" name="name"
                  value={userData.name}
                  onChange={userHandleChange}
                  id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name" required />
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                  <input 
                  type="email" name="email"
                  value={userData.email}
                  onChange={userHandleChange}
                  id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                  <input type="password"
                  value={userData.password}
                  onChange={userHandleChange}
                  
                  name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>
              
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                  </div>
                </div>
                <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create an account</button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account? <Link to={`/SignIn`} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</Link>
                </p>
              </form>
        </div>

      
          </div> );
}

export default SignUp;


