import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";



export const postContact = createAsyncThunk(
  "contact/postAdminContact",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/client/contact`,
          
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification("Message sent sucessful.You will be contacted soon", {
          variant: "success",
        });
        params.resetForm();
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to send message ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save contact!");
    }
  }
);



const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContact.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postContact.fulfilled, (state, action) => {
        // state.contact = action.payload;
        // state.contact.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.contact[state.contact.length]=action.payload
        // let data=[];
        // data = state.contact.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        // state.contact=data;
        state.status = "idle";
      })
      .addCase(postContact.rejected, (state, action) => {
        // state.contact=action.payload
        state.status = "failed";
      }) 
      ;
  },
});
export default contactSlice;

// extraReducers: {
//     [getContact.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.contact = action.payload;
//     },
//     [getContact.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postContact.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postContact.fulfilled]: (state, action) => {
//         state.contacts = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postContact.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
