import { BrowserRouter, Link, Route } from "react-router-dom";
import Hero from "./Hero";
import Carousel from "../carousel/carousel";
import ProductCard from "../productCard/productCard";
import ProductNewReleaseCard from "../productCard/productNewReleaseCard";
import ProductOfferBox from "../productOfferBox/productOfferBox";
import demoImage1 from "./demoImage1.jpg";
import demoImage2 from "./demoImage2.jpg";
import demoImage3 from "./demoImage2.jpg";
import ProductCard1 from "./ProductCard1";
import Products from "./PCard";

import About from "../pages/About";
import Contact from "../pages/Contact";
// import Footer from '../footer/footer';
import Search from "../../Test/Search";
import Snav from "../../Test/Snav";
import { items, portfolioItems } from "./ddata";
import Explore from "./Explore";
import ImageGallery from "./ImageGallery";

import bannerImage1 from "../../demoImages/banner1.jpg";
import bannerImage2 from "../../demoImages/banner2.jpg";
import Features from "./Features";
import Portfolio from "../pages/Portfolio";
import PortfolioCard from "../portfolioCard/portfolioCard";
import ServiceList from "../servicesList/serviceList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBanner } from "../../store/client/banner";
import { getProduct } from "../../store/client/product";
import { getAbout } from "../../store/client/about";
import { getService } from "../../store/client/service";
import { getProject } from "../../store/client/project";
import { useState } from "react";

function Home() {
  const[productImages,setProductImages]=useState([]);
  const dispatch=useDispatch();
  const {banner}=useSelector(state=>state.clientBanner);
  const {about}=useSelector(state=>state.clientAbout);

  const {service}=useSelector(state=>state.clientService);

  const {product}=useSelector(state=>state.clientProduct);
  const {project}=useSelector(state=>state.clientProject);


  useEffect(()=>{
dispatch(getBanner());
dispatch(getProduct());
dispatch(getAbout());
dispatch(getService());
dispatch(getProject());



  },[])

  useEffect(()=>{
    let data=[];
product?.map(item=>{
  // console.log(item);
data.push({
 src: item.productImages[0],
 title:item.productName
  
}
  );
})
setProductImages(data);
console.log(data);

  },[product])

  return (
    <div className=" ">
      {/* slider crousal */}
      <div
        id="default-carousel"
        className="relative h-auto"
        data-carousel="slide"
        data-ride="carousel"
      >
        {/* Carousel wrapper */}
        <div className="relative h-56 overflow-hidden rounded-xs sm:h-[calc(100vh-3.25rem)]">
          {/* Item 1 */}
          <div
            className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20"
            data-carousel-item
          >
            <img
              src={banner?.bannerImage}
              className="absolute block h-full w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            />

            <div className="absolute  w-full h-full max-h-[500px]  text-black flex flex-col justify-center">
              <h1 className="!px-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-justify mb-2">
                {" "}
                <span className="text-red-800">{banner?.bannerTitle}</span>
              </h1>
              <h1 className="!px-4 text-md sm:text-xl md:text-xl lg:text-2xl font-semibold text-justify">
                {" "}
                <span className="text-blue-800"
                dangerouslySetInnerHTML={{__html: banner?.bannerDescription}}
                ></span>
              </h1>
            </div>
          </div>{" "}

          <div
            className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20"
            data-carousel-item
          >
            <img
              src={banner?.bannerImage}
              className="absolute block h-full w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            />

            <div className="absolute  w-full h-full max-h-[500px]  text-black flex flex-col justify-center">
              <h1 className="!px-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-justify mb-2">
                {" "}
                <span className="text-red-800">{banner?.bannerTitle}</span>
              </h1>
              <h1 className="!px-4 text-md sm:text-xl md:text-xl lg:text-2xl font-semibold text-justify">
                {" "}
                <span className="text-blue-800"
                dangerouslySetInnerHTML={{__html: banner?.bannerDescription}}
                ></span>
              </h1>
            </div>
          </div>{" "}
          {/* <div
            className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20"
            data-carousel-item
          >
            <img
              src={bannerImage2}
              className="absolute block h-full w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            />

            <div className="absolute  w-full h-full max-h-[500px]  text-black flex flex-col justify-center">
              <h1 className="!px-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-justify mb-2">
                {" "}
                <span className="text-red-800">WEAVE-DECOR</span>
              </h1>
              <h1 className="!px-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-justify">
                {" "}
                <span className="text-blue-800">See what we </span>Delivered
              </h1>
            </div>
          </div>{" "} */}
        </div>
        {/* Slider indicators */}
        <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
          <button
            type="button"
            className="w-3 h-3 rounded-full bg-white dark:bg-gray-800"
            aria-current="true"
            aria-label="Slide 1"
            data-carousel-slide-to={0}
          />
          <button
            type="button"
            className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800"
            aria-current="false"
            aria-label="Slide 2"
            data-carousel-slide-to={1}
          />
          <button
            type="button"
            className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800"
            aria-current="false"
            aria-label="Slide 3"
            data-carousel-slide-to={2}
          />
        </div>
        {/* Slider controls */}
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>

      {/* About us Sections */}
      <section className="bg-white dark:bg-gray-900">
        <div className="grid  px-4 py-8  lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className=" place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-4xl dark:text-white">
              WEAVE-DECOR
            </h1>
            <p className="max-w-2xl mb-2 font-light text-gray-500 lg:mb-2 md:text-lg lg:text-xl dark:text-gray-400">
            <span className="text-4xl text-teal-400">{about?.aboutTheme}</span>{" "}
              
            </p>
            {/* <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              Get started
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a> */}
            <p 
            dangerouslySetInnerHTML={{__html: about?.aboutDescription}}
            className="m-2 text-justify">
             
            </p>
            <Link
              to={`/about`}
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              See More
            </Link>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src={about?.aboutImage}
                            alt="mockup"
            />
          </div>
        </div>
      </section>

      {/* services section */}
      {/* <Features /> */}
      <div className="  m-4">
      <h1 className="  m-1 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-start">Our Services</h1>
         <div className="grid grid-cols-1 sm:grid-cols-2">

          
         {
            service?.map((item,index)=>{
              if(index>5){
               return<></>
              }
              return <ServiceList
              title={item.serviceTitle}
              description={item.serviceDescription}
            />
            })
          }

       
         </div>
         <div className="mt-4 flex justify-center items-center">
          <Link
            to={`/services`}
            className="inline-flex items-center dark:border-gray-500 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-400 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:hover:bg-gray-800 dark:focus:ring-gray-800 "
          >
            See More
          </Link>
        </div>

      </div>

      {/* portfolio sections */}
      <div className="mt-6">
      <h1 className="  m-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-center">Our Projects</h1>
      <div className="xxs:m-4 xs:m-0 sm:m-6 mt-0 grid grid-cols-1 gap-1     xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
        {project?.map((portfolioData, index) => {
          return <PortfolioCard key={index} portfolioData={portfolioData} />;
        })}
      </div>
      <div>
      <div className="mt-4 flex justify-center items-center">
          <Link
            to={`/portfolio`}
            className="inline-flex items-center dark:border-gray-500 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-400 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:hover:bg-gray-800 dark:focus:ring-gray-800 "
          >
            See More
          </Link>
        </div>
      </div>
      </div>
     

      {/* products section */}
      {/* <Hero /> */}
      <div className="mx-5  p-1">
      <h1 className="  m-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-center">Products</h1>

        <ImageGallery images={productImages} />
        <div className="mt-4 flex justify-center items-center">
          <Link
            to={`/products`}
            className="inline-flex items-center dark:border-gray-500 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-400 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:hover:bg-gray-800 dark:focus:ring-gray-800 "
          >
            See More
          </Link>
        </div>
      </div>

      <div className='mt-[10px]'>
   <Carousel/>
   </div>
    </div>
  );
}

export default Home;
