import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getSiteSetting = createAsyncThunk(
  "siteSetting/getAdminSiteSetting",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/sitesetting`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Site Settings fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch siteSettings!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all siteSetting!");
      
    }
  }
);

export const postSiteSetting = createAsyncThunk(
  "siteSetting/postAdminSiteSetting",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/sitesetting`,
        params.formData
      );
      // params.resetForm();
      // console.log(response);
      if(response.data.sucess){
        params.notification("Site Settings Saved Sucessfully", {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save site Settings", {
        variant: "error",
      });
      return rejectWithValue("Failed to save Site settings!");
    }
  }
);

const siteSettingSlice = createSlice({
  name: "siteSetting",
  initialState: {
    siteSetting: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteSetting.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSiteSetting.fulfilled, (state, action) => {
        state.siteSetting = action.payload;
        state.status = "idle";
      })
      .addCase(getSiteSetting.rejected, (state, action) => {
        // state.siteSetting=action.payload
        state.status = "failed";
      })
      .addCase(postSiteSetting.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postSiteSetting.fulfilled, (state, action) => {
        state.siteSetting = action.payload;
        state.status = "idle";
      })
      .addCase(postSiteSetting.rejected, (state, action) => {
        // state.siteSetting=action.payload
        state.status = "failed";
      });
  },
});
export default siteSettingSlice;

// extraReducers: {
//     [getSiteSetting.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.siteSetting = action.payload;
//     },
//     [getSiteSetting.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postSiteSetting.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postSiteSetting.fulfilled]: (state, action) => {
//         state.siteSettings = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postSiteSetting.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
