import React from "react";
import Courses from "./Courses";
import Acc from "./Acc";
import { accordions } from "./Dataa";
import ServiceList from "../../servicesList/serviceList";
import { Link } from "react-router-dom";
import TestFAQ from "../../../ServicesQns/TestFAQ";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getService } from "../../../store/client/service";
import { getQuestionAnswer } from "../../../store/client/questionAnswer";

const Services = () => {
  const { service } = useSelector((state) => state.clientService);

  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(getService());
dispatch(getQuestionAnswer());
  },[])
  return (
    <div className="p-1">
      <div className="m-4 mb-0">
        <h1 class="mt-2 mb-2 text-2xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white text-start">
          Our Services
        </h1>
        
        <p className="font-normal text-lg  text-black dark:text-white text-justify">
          Interior design is much more than transforming a certain space with
          aesthetics. With a perfect blend of comfort and functionality, it
          optimizes user experience and appeals to emotion. Interior designers
          in the Philippines are trained to create and maximize space, and
          further up its value through color choices, sustainability features
          and more. The best interior designers take these all into account, and
          further improve on quality of life through detailed designs and
          decors.
        </p>
        <p class="max-w-2xl mb-2  font-semibold text-gray-900 lg:mb-2 md:text-lg lg:text-xl  dark:text-white">
            Our Services includes:
              </p>
        <div className="grid grid-cols-1 sm:grid-cols-2">

          {
            service?.map(item=>{
              return <ServiceList
              title={item.serviceTitle}
              description={item.serviceDescription}
            />
            })
          }
          
         
           
        </div>
        <div className="mt-4 flex justify-center items-center">
          <Link
            to={`/products`}
            class="inline-flex items-center dark:border-gray-500 justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-400 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:hover:bg-gray-800 dark:focus:ring-gray-800 "
          >
            Explore Us
          </Link>
        </div>
      </div>
      <TestFAQ/>

      {/* <div className="">
        {accordions.map((accordion) => {
          return <Acc key={accordion.id} {...accordion} />;
        })}
      </div> */}
        
    </div>
  );
};

export default Services;
