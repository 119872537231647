import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Item from "./Item";
// import { PRODUCTS, RESOURCES, COMPANY, SUPPORT, CONTACT } from "./Menus";
// import weaveLogo from '../../demoImages/'
const ItemsContainer = () => {
  const [support,setSupport]=useState([]);
  const {siteSetting} =useSelector(state=>state.clientSiteSetting);

const[contact,setContact]=useState([]);
const[company,setCompany]=useState([]);

 

  useEffect(()=>{
    const supportData=[]
   

    setCompany([
      { name: `${siteSetting?.siteAddress}`, link: "" },
      { name: `${siteSetting?.siteOfficeHours}`, link: "" }
     
    ])
  
    setContact([
      { name: `${siteSetting?.siteContactNumber}`, link: "" },
      { name: `${siteSetting?.siteEmail}`, link: "" }
    ]);
 
   const supportList=siteSetting?.siteSupport?.split(",");
   supportList?.map(data=>{
    console.log(data);
    supportData.push({
      name:data,
      link:""
    })

   })
   setSupport(supportData)
  //  console.log(SUPPORT);
  },[siteSetting])

  // console.log(SUPPORT);

// console.log(company);
  return (
    <div className="w-full text-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16">
      {/* <Item Links={RESOURCES} title="RESOURCES" /> */}
      <div className=" flex items-center justify-center flex-row">
        <img className="h-14 w-14" src={siteSetting?.siteLogo} alt="weave logo"></img>
        {/* <p>Build your dream architecture with weave.We build what you</p> */}
      </div>
      <Item Links={company} title="WEAVE DECORS" />
      <Item  Links={contact} title="CONTACTS"  />  
      <Item Links={support} title="SUPPORT" />
    </div>
  );
};

export default ItemsContainer;
