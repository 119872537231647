import { Route, Routes } from "react-router-dom";
import SignIn from "./components/auth/signIn";
import SignUp from "./components/auth/signUp";
import MyCart from "./components/Cart/myCart";
import ProductDetailsClient from "./components/client/productDetailsClient";
import Dashboard from "./components/dashboard/dashboard";
import Kanban from "./components/dashboard/Kanban";
import Home from "./components/home/home";
import AllProducts from "./components/pages/allProducts";
import CustomerService from "./components/pages/customerService";
import Sell from "./components/pages/sell";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Portfolio from "./components/pages/Portfolio";
import ProductTest from "./components/productCard/ProductTest";
import PTest from "./components/pages/Port/PTest";
import Gallery from "./components/pages/Gallery";
import Services from "./components/pages/Services/Services";
import ContactUs from "./Contact/ContactUs";
import ProjectDetails from "./components/portfolioCard/projectDetails";
import ProTest from "./components/productCard/ProTest";
import Password from "./components/Password/Password";
import NotFound from "./components/pages/Not/NotFound";
// import Popup from "./components/productCard/Popup";
import View from "./components/productCard/View";
import PrivacyPolicy from "./Static/PrivacyPolicy";
import Terms from "./Static/Terms";
import { useSelector } from "react-redux";

function MainRouter() {
  const { status, auth } = useSelector((state) => state.auth);

  return (
    <div className="dark:bg-gray-900 min-h-screen dark:text-white">
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/SignIn" element={<SignIn />}></Route>
        <Route exact path="/SignUp" element={<SignUp />}></Route>
        {/* <Route exact path="/MyCart" element={<MyCart />}></Route> */}
        {/* <Route
          exact
          path="/Products/Customer%20Service"
          element={<CustomerService />}
        ></Route> */}

        {/* <Route
          exact
          path="/Product/123"
          element={<ProductDetailsClient />}
        ></Route>
        <Route
          exact
          path="/CustomerService"
          element={<CustomerService />}
        ></Route> */}
        {/* <Route exact path="/Products/All/*" element={<AllProducts />}></Route> */}
        <Route exact path="/About" element={<About />}></Route>
        <Route exact path="/Contact" element={<Contact />}></Route>
        <Route
          exact
          path="/project/:projectId"
          element={<ProjectDetails />}
        ></Route>

        <Route exact path="/Portfolio" element={<Portfolio />}></Route>
        <Route exact path="/products" element={<ProductTest />}></Route>
        {/* <Route exact path="/Port" element={<PTest />}></Route> */}
        {/* <Route exact path="/gall" element={<Gallery />}></Route> */}
        <Route exact path="/services" element={<Services />}></Route>
        {/* <Route exact path="/contactus" element={<ContactUs />}></Route> */}
        {/* <Route exact path="/Protest" element={<ProTest />}></Route> */}
        <Route exact path="/Password-reset" element={<Password />}></Route>
        {/* <Route exact path="/Product" element={<ProTest />}></Route> */}
        {/* <Route path="/product/:id" element={<View />} /> */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/*" element={<NotFound />} />

        {/* admin Routes */}
        {auth?.role === "admin" && (
          <Route path="/Dashboard/*" element={<Dashboard />}></Route>
        )}
      </Routes>
    </div>
  );
}

export default MainRouter;
