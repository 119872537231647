// import React from 'react'
// import { products } from '../home/ddata'
// import NewProductCard from './NewProductCard'

// const ProductTest = () => {
//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-2xl font-bold text-gray-900 mb-4">Our Products</h1>
//       <div className="flex flex-wrap -mx-2">
//         {products.map(product => (
//           <div key={product.id} className="w-full md:w-1/2 lg:w-1/3 px-2 mb-4">
//             <NewProductCard productInfo={product} price={product.price} starRating={product.starRating} imageUrl={product.imageUrl} />
//           </div>
//         ))}
//       </div>
//     </div>
//   )
// }

// export default ProductTest
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../../store/client/product';
import Food from './Food'

const ProductTest = () => {
  const {product}=useSelector(state=>state.clientProduct);

    const dispatch=useDispatch();
    useEffect(()=>{
  dispatch(getProduct());
  // dispatch(getSiteSetting());
  
  
    },[])
  return (
    <div>
    {/* <div className='max-w-[1240px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6'>
        <div className='rounded-xl relative hover:scale-105 duration-500 cursor-pointer'>
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className=' font-bold text-2xl px-2 pt-4'>kitchen</p>
                <p className='px-2'>Trending</p>
            </div>
            <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl' src="https://images.unsplash.com/photo-1556911220-bff31c812dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8a2l0Y2hlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60" alt="" />
        </div>
        <div className='rounded-xl relative hover:scale-105 duration-500 cursor-pointer'>
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className=' font-bold text-2xl px-2 pt-4'>Woods</p>
                <p className='px-2'>Trending</p>
            </div>
            <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl' src="https://images.unsplash.com/photo-1517594632980-535c0c33173d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHdvb2RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1400&q=60" alt="" />
        </div>
        <div className='rounded-xl relative hover:scale-105 duration-500 cursor-pointer'>
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className=' font-bold text-2xl px-2 pt-4'>Bathroom</p>
                <p className='px-2'>explore more</p>
            </div>
            <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl' src="https://images.unsplash.com/photo-1564540583246-934409427776?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YmF0aHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60" alt="" />
        </div>
    </div> */}
    <Food/>
    </div>
  )
}

export default ProductTest