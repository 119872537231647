import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useCustomNotification from '../utils/notification';
import { useDispatch } from 'react-redux';
import { socialSignIn } from '../store/client/auth';



function SocialLogin() {
const notification=useCustomNotification()
  const navigate=useNavigate();
  const dispatch=useDispatch();

  const responseGoogle = (response) => {
   console.log(response);

   let decodedData=jwt_decode(response.credential);
   console.log(decodedData);

   const formData=new FormData();
   formData.append('name',decodedData.given_name);
   formData.append('email',decodedData.email);
   formData.append('password',decodedData.jti);
   
   dispatch(socialSignIn({formData,notification}))

    // const user = {
    //   name: response.profileObj.givenName,
    //   email: response.profileObj.email,
    //   password: response.profileObj.googleId,
    // };
   
  };


  return (
    <div>
      <GoogleLogin
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
}

export default SocialLogin;