import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getQuestionAnswer = createAsyncThunk(
  "questionAnswer/getAdminQuestionAnswer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/questionAnswer`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("QuestionAnswer fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch questionAnswer!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all questionAnswer!");
      
    }
  }
);

export const postQuestionAnswer = createAsyncThunk(
  "questionAnswer/postAdminQuestionAnswer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/questionanswer`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save questionAnswer ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save questionAnswer!");
    }
  }
);

export const deleteQuestionAnswer = createAsyncThunk(
  "questionAnswer/deleteAdminQuestionAnswer",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/admin/questionanswer`,
      {
        data:params.formData
      }
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to delete questionAnswer ", {
        variant: "error",
      });
      return rejectWithValue("Failed to delete questionAnswer!");
    }
  }
);

const questionAnswerSlice = createSlice({
  name: "questionAnswer",
  initialState: {
    questionAnswer: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionAnswer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getQuestionAnswer.fulfilled, (state, action) => {
        state.questionAnswer = action.payload;
        // state.questionAnswer=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getQuestionAnswer.rejected, (state, action) => {
        // state.questionAnswer=action.payload
        state.status = "failed";
      })
      .addCase(postQuestionAnswer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postQuestionAnswer.fulfilled, (state, action) => {
        // state.questionAnswer = action.payload;
        // state.questionAnswer.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.questionAnswer[state.questionAnswer.length]=action.payload
        let data=[];
        data = state.questionAnswer.filter((ser) => ser._id != action.payload._id);
        data.push(action.payload);
        state.questionAnswer=data;
        state.status = "idle";
      })
      .addCase(postQuestionAnswer.rejected, (state, action) => {
        // state.questionAnswer=action.payload
        state.status = "failed";
      }) .addCase(deleteQuestionAnswer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteQuestionAnswer.fulfilled, (state, action) => {
        // state.questionAnswer = action.payload;
        // state.questionAnswer.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.questionAnswer[state.questionAnswer.length]=action.payload
        let data=[];
        data = state.questionAnswer.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        state.questionAnswer=data;
        state.status = "idle";
      })
      .addCase(deleteQuestionAnswer.rejected, (state, action) => {
        // state.questionAnswer=action.payload
        state.status = "failed";
      });
      ;
  },
});
export default questionAnswerSlice;

// extraReducers: {
//     [getQuestionAnswer.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.questionAnswer = action.payload;
//     },
//     [getQuestionAnswer.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postQuestionAnswer.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postQuestionAnswer.fulfilled]: (state, action) => {
//         state.questionAnswers = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postQuestionAnswer.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
