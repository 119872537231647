import { FiPenTool } from "react-icons/fi";
import { FaLaptopCode } from "react-icons/fa";
import { BsBarChartLine } from "react-icons/bs";
import { MdOutlineScience } from "react-icons/md";


export const categories = [
  {
    id: 1,
    icon: <FiPenTool />,
    category: "Design",
    linkto:"https://images.unsplash.com/photo-1502311526760-ebc5d6cc0183?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60"
  },
  {
    id: 2,
    icon: <FaLaptopCode />,
    category: "Development",
    linkto:"https://images.unsplash.com/photo-1502311526760-ebc5d6cc0183?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60"
 
  },
  {
    id: 3,
    icon: <BsBarChartLine />,
    category: "Business",
    linkto:"https://images.unsplash.com/photo-1502311526760-ebc5d6cc0183?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60"
 
  },
  {
    id: 4,
    icon: <MdOutlineScience />,
    category: "Science",
    linkto:"https://images.unsplash.com/photo-1502311526760-ebc5d6cc0183?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60"
 
  },
];

export const courses = [
  {
    id: 1,
    image:"https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1400&q=60",
    category: "Web Design",
    title: "The Complete Web Design Course",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 2,
    image: "https://images.unsplash.com/photo-1534067783941-51c9c23ecefd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG1vdW50YWlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1400&q=60",
    category: "Web Development",
    title: "FullStack Web Development Course",
    rating: 4.8,
    participants: 700,
    price: 125,
  },
  {
    id: 3,
    image: "https://images.unsplash.com/photo-1421790500381-fc9b5996f343?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60",
    category: "Web Development",
    title: "Python Beginner to Advanced Course",
    rating: 4.9,
    participants: 300,
    price: 99,
  },
  {
    id: 4,
    image:"https://images.unsplash.com/photo-1530398231547-70832467b336?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8ZmxhdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60",
    category: "Web Design",
    title: "UI/UX Design Crazy Course With Harrys",
    rating: 4.7,
    participants: 600,
    price: 85,
  },
  {
    id: 5,
    image:"https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60",
    category: "AI Development",
    title: "AI Development With Python (complete beginner course)",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 6,
    image:"https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1400&q=60",
    category: "Web Design",
    title: "The Complete Web Design Course",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 7,
    image:"https://images.unsplash.com/photo-1545308562-050974fb9ac4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60",
    category: "Web Design",
    title: "The Complete Web Design Course",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 8,
    image:"https://images.unsplash.com/photo-1502311526760-ebc5d6cc0183?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=1400&q=60",
    category: "Web Design",
    title: "The Complete Web Design Course",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
];


export const accordions = [
  {
    id: 1,
    title: "What is  Weave-decor?",
  },
  {
    id: 2,
    title: "What are the services?",
  },
  {
    id: 3,
    title: "what are the things?",
  },
  {
    id: 4,
    title: "Why is ayush so handsome?",
  },
];
