import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy, postPrivacyPolicy } from "../../../store/admin/privacyPolicy";
import useCustomNotification from "../../../utils/notification";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";


function PrivacyPolicy() {
  const notification=useCustomNotification();
  const dispatch=useDispatch();
  const [dataCkEditor, setDataCkEditor] = useState("");
  const {privacyPolicy,status}=useSelector(state=>state.adminPrivacyPolicy);

useEffect(()=>{
dispatch(getPrivacyPolicy({notification}));
},[])

useEffect(()=>{
  if(privacyPolicy && privacyPolicy.details){
    // console.log(privacyPolicy);
    setDataCkEditor(privacyPolicy.details);

  }
},[privacyPolicy])
  


  const handleSubmitButton=(e)=>{
    e.preventDefault();
    const formData=new FormData();
    formData.append('_id',privacyPolicy?._id);
    formData.append('details',dataCkEditor);
    dispatch(postPrivacyPolicy({formData,notification}))
    console.log(dataCkEditor);
  }




  if(status=="loading")
{
  return(<>
  <LoadingSpinner/>
  </>)
}
    return ( <div>
         <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Privacy Policy
          </h5>
          

          
          <div className="w-11/12"  >
           
              <CKeditor
              name="PrivacyPolicy"
               
              content={dataCkEditor}
                setContent={setDataCkEditor}
                initialHeight="50vh"
                // editorLoaded={editorLoaded}
              />
              {/* {JSON.stringify(dataCkEditor)} */}
           
          </div>

         

         
         

        

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div> );
}

export default PrivacyPolicy;