import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getPrivacyPolicy = createAsyncThunk(
    "privacyPolicy/getClientPrivacyPolicy",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/privacypolicy`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all privacyPolicy!");
        }
    })

const privacyPolicySlice = createSlice({
    name: "privacyPolicy",
    initialState: {
        privacyPolicy:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getPrivacyPolicy.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
            state.privacyPolicy=action.payload
            state.status = 'idle'
          }) .addCase(getPrivacyPolicy.rejected, (state, action) => {
            // state.privacyPolicy=action.payload
            state.status = 'failed'
          })
      }
    
})
export default privacyPolicySlice;

// extraReducers: {
//     [getPrivacyPolicy.fulfilled]: (state, { payload }) => {
//         state.privacyPolicy = payload;
//     },
// }