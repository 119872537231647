import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUserRole } from "../../store/admin/user";
import useCustomNotification from "../../utils/notification";
import ConfrimationModal from "../confirmationModal/confirmationModal";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import UsersTable from "./Tables/usersTable";



function Users() {
    const notification = useCustomNotification();
    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(getUser({notification}))
  
    },[])

    const [userUpdateId,setUserUpdateId]=useState(undefined);

    const {status,user}=useSelector(state=>state.adminUser);
 
  
    const [updateModalState, setUpdateModalState] = useState(false);
  
    const onHandleButtonClick = (id) => {
      setUpdateModalState(true);
      setUserUpdateId(id)
      console.log("dd");
    };

    const onHandleEditClick=()=>{

    }
  
    const confirmUpdate = () => {
      console.log("update confirm");
      // const formData=new FormData();
      // formData.append('_id',userUpdateId);
      const selectedUserId=userUpdateId
  
      dispatch(updateUserRole({selectedUserId,notification}));
      setUserUpdateId(undefined)
    setUpdateModalState(false);
    };
    const cancelUpdate = () => {
      console.log("update cancel");
    setUpdateModalState(false);
    };

    useEffect(() => {}, [updateModalState,user]);

    if (status == "loading") {
        return (
          <>
            <LoadingSpinner />
          </>
        );
      }
    return ( <div>
        <UsersTable
        list={user}
        handleEdit={onHandleEditClick}
        // handleDelete={onHandleButtonClick}
        handleUpdate={onHandleButtonClick}


        />
        {/* confirmation delete  */}

      {updateModalState && (
        <ConfrimationModal
          handleConfirm={confirmUpdate}
          handleCancel={cancelUpdate}
          confirmationMessage={"Are you Sure?Do You want to change role of user?"}
        />
      )}
    </div> );
}

export default Users;