import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getSiteSetting = createAsyncThunk(
    "siteSetting/getClientSiteSetting",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/sitesetting`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all siteSetting!");
        }
    })

const siteSettingSlice = createSlice({
    name: "siteSetting",
    initialState: {
        siteSetting:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getSiteSetting.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getSiteSetting.fulfilled, (state, action) => {
            state.siteSetting=action.payload
            state.status = 'idle'
          }) .addCase(getSiteSetting.rejected, (state, action) => {
            // state.siteSetting=action.payload
            state.status = 'failed'
          })
      }
    
})
export default siteSettingSlice;

// extraReducers: {
//     [getSiteSetting.fulfilled]: (state, { payload }) => {
//         state.siteSetting = payload;
//     },
// }