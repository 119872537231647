import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import { data } from "autoprefixer";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSetting, postSiteSetting } from "../../../store/admin/siteSetting";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";
// import demoProductImage from "../../demoImages/productDemoImage1.jpg";
import { useSnackbar } from 'notistack';
import { Fragment } from "react";
import useCustomNotification from "../../../utils/notification";

const categoryList = [
  { value: "Electronics", label: "Electronics" },
  { value: "Cameras", label: "Cameras" },
  { value: "Laptops", label: "Laptops" },
  { value: "Accessories", label: "Accessories" },
  { value: "Headphones", label: "Headphones" },
  { value: "Food", label: "Food" },
  { value: "Books", label: "Books" },
  { value: "Clothes/Shoes", label: "clothes/Shooes" },
  { value: "Beauty/Health", label: "Beauty/Health" },
  { value: "Sports", label: "Sports" },
  { value: "Outdoor", label: "Outdoor" },
  { value: "Home", label: "Home" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "w-full",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

function SiteSettings() {
  const notification =useCustomNotification()
const dispatch=useDispatch();
const { enqueueSnackbar, closeSnackbar } = useSnackbar();

const[imagePreview,setImagePreview]=useState(null);
useEffect(()=>{
  // setDataCkEditor(true);
  dispatch(getSiteSetting({notification}));
//  notification("ddddd",{variant:"error"})
},[]);

const {siteSetting,status}=useSelector(state=>state.adminSiteSetting)
  // const [editorLoaded, setEditorLoaded] = useState(false);
  const [dataCkEditor, setDataCkEditor] = useState("");

  //for form data
  const [siteSettingData,setSiteSettingData] = useState({
    siteName: "",
    siteLogo: "",
    siteTagLine:"",
    siteDescription:"",
    siteEmail: "",
    siteAddress:"",
    siteOfficeHours:"",
    siteContactNumber:"",
    siteSupport:"",
    siteInstagramProfileLink:"",
    siteFacebookProfileLink:"",
    siteTwitterProfileLink:"",
    siteTikTokProfileLink:"",
    siteMetaKeywords:"",
    siteMetaDescription:""
  });

  const siteSettingHandleChange = async(e) => {
    console.log(e.target.name);
    if (e.target.name === "siteLogo") {
      const file = e.target.files[0];
      //  resizeFile(file).then(image=>{
      //    console.log(image);
      //    setAvatarPreview(image);
      //    setAvatar(image);
      //  });
      // console.log(file);
      if(file.type=="image/jpeg"||file.type=="image/webp" || file.type=="image/png" ){
        setSiteSettingData({...siteSettingData,siteLogo:file})
        const url=URL.createObjectURL(file);
        setImagePreview(url);
      }else{
        setImagePreview(noImageLogo);
      }

      
      
    } else {
      setSiteSettingData({ ...siteSettingData, [e.target.name]: e.target.value });
    }
  };


  useEffect(()=>{
setSiteSettingData({...siteSettingData,siteDescription:dataCkEditor});
console.log(siteSetting);
  },[dataCkEditor])

//   const fillForm = (data) => {
//     setSiteSettingData({
//     siteName: data.siteName,
//     siteLogo: data.siteLogo,
//     siteTagLine:data.siteTagLine,
//     siteDescription:data.siteDescription,
//     siteEmail: data.siteEmail,
//     siteAddress:data.siteAddress,
//     siteOfficeHours:data.siteOfficeHours,
//     siteContactNumber:data.siteContactNumber,
//     siteSupport:data.siteSupport,
//     siteInstagramProfileLink:data.siteInstagramProfileLink,
//     siteFacebookProfileLink:data.siteFacebookProfileKink,
//     siteTwitterProfileLink:data.siteTwitterProfileLink,
//     siteTikTokProfileLink:data.siteTwitterProfileLink,
//     siteMetaKeyWords:data.siteMetaKeywords,
//     siteMetaDescription:data.siteMetaDescription
//     })
    
// }

const resetForm=()=>{
  setSiteSettingData({
    siteName: "",
    siteLogo: "",
    siteTagLine:"",
    siteDescription:"",
    siteEmail: "",
    siteAddress:"",
    siteOfficeHours:"",
    siteContactNumber:"",
    siteSupport:"",
    siteInstagramProfileLink:"",
    siteFacebookProfileLink:"",
    siteTwitterProfileLink:"",
    siteTikTokProfileLink:"",
    siteMetaKeywords:"",
    siteMetaDescription:""
  })
}
  const handleSubmitButton=(e)=>{
    e.preventDefault();
        const formData = new FormData()
        formData.append("_id", siteSetting?._id);
        formData.append("siteName", siteSettingData.siteName?.trim() ||'');
        formData.append("siteTagLine", siteSettingData.siteTagLine?.trim() ||'');
        formData.append("siteLogo", siteSettingData.siteLogo || '');
        formData.append("siteSupport", siteSettingData.siteSupport || '');
        formData.append("siteDescription", siteSettingData.siteDescription?.trim() || '')
        formData.append("siteContactNumber", siteSettingData.siteContactNumber?.trim() || '')
        formData.append("siteAddress", siteSettingData.siteAddress?.trim() || '')
        formData.append("siteEmail", siteSettingData.siteEmail?.trim() || '')
        formData.append("siteFacebookProfileLink", siteSettingData.siteFacebookProfileLink?.trim() || '')
        formData.append("siteInstagramProfileLink", siteSettingData.siteInstagramProfileLink?.trim() || '')
        formData.append("siteTwitterProfileLink", siteSettingData.siteTwitterProfileLink?.trim() || '')
        formData.append("siteTikTokProfileLink", siteSettingData.siteTikTokProfileLink?.trim() || '')
        formData.append("siteMetaKeywords", siteSettingData.siteMetaKeywords?.trim() || '')
        formData.append("siteMetaDescription", siteSettingData.siteMetaDescription?.trim() || '')
        formData.append("siteOfficeHours", siteSettingData.siteOfficeHours?.trim() || '')
        dispatch(postSiteSetting({ formData,notification }));

    console.log(siteSettingData);
  }

  useEffect(()=>{
setSiteSettingData(siteSetting);
setImagePreview(siteSetting?.siteLogo);

if(siteSetting && siteSetting.siteDescription){
  setDataCkEditor(siteSetting.siteDescription)
}
  },[siteSetting])

// console.log(siteSettingData);
 if(status=="loading")
{
  return(<>
  <LoadingSpinner/>
  </>)
}
  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Site Settings
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Site Name
            </label>
            <input
              type="text"
              name="siteName"
              onChange={siteSettingHandleChange}
              id="siteName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="site name"
              value={siteSettingData?.siteName}
              // //required

            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1">
              <label
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                for="file_input"
              >
                Upload Site Logo
              </label>
              <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="file_input"
                type="file"
                name="siteLogo"
                // value={siteSettingData?.siteLogo?siteSettingData.siteLogo:""}
                onChange={siteSettingHandleChange}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                SVG, PNG, JPG  (MAX. 56x56).
              </p>
            </div>
            <div className="h-14 w-14 flex items-center">
              <img 
              src={ imagePreview?imagePreview:""}
              className="object-contain"
               alt="siteLogo" />
            </div>
          </div>

          <div className="w-11/12">
            <label
              htmlFor="tagline"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Tag line
            </label>
            <input
              type="text"
              name="siteTagLine"
              id="brand"
              onChange={siteSettingHandleChange}
              value={siteSettingData?.siteTagLine}

              placeholder="tagline in 5 words (Weave Your Design With Us"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12 "  >
           
              <CKeditor
              name="Site Description"
               
              content={dataCkEditor}
                setContent={setDataCkEditor}
                // editorLoaded={editorLoaded}
              />
              {/* {JSON.stringify(dataCkEditor)} */}
           
          </div>

          <div className="w-11/12">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Email
            </label>
            <input
              type="text"
              name="siteEmail"
              id="email"
              placeholder="email"
              value={siteSettingData?.siteEmail}
              onChange={siteSettingHandleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Address
            </label>
            <input
              type="text"
              name="siteAddress"
              id="address"
              placeholder="kupondole,Lalitpur"
              value={siteSettingData?.siteAddress}
              onChange={siteSettingHandleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required

            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="officeHours"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Office Hours
            </label>
            <input
              type="text"
              name="siteOfficeHours"
              id="officeHours"
              placeholder="10AM-6PM"
              value={siteSettingData?.siteOfficeHours}
              onChange={siteSettingHandleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="contact no"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Contact Number
            </label>
            <input
              type="number"
              name="siteContactNumber"
              id="contactNumber"
              placeholder="9812345678,44567889"
              value={siteSettingData?.siteContactNumber}
              onChange={siteSettingHandleChange}
              min="0"
              onInput={(e) => {
                e.target.validity.valid || (e.target.value = "");
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="support"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Support
            </label>
            <input
              type="text"
              name="siteSupport"
              value={siteSettingData?.siteSupport}
              onChange={siteSettingHandleChange}
              id="support"
              placeholder={`esewa,Imepay,...(list of names seperated by ",")`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="instagramProfileLink"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Instagram Profile Link
            </label>
            <input
              type="text"
              name="siteInstagramProfileLink"
              id="instagramProfileLink" 
               value={siteSettingData?.siteInstagramProfileLink}

              onChange={siteSettingHandleChange}
              placeholder={`https://www.instagram.com/username`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="facebookProfileLink"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Facebook Profile Link
            </label>
            <input
              type="text"
              name="siteFacebookProfileLink"
              onChange={siteSettingHandleChange}
              value={siteSettingData?.siteFacebookProfileLink}
              id="facebookProfileLink"
              placeholder={`https://www.facebook.com/username`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="twitterProfileLink"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Twitter Profile Link
            </label>
            <input
              type="text"
              name="siteTwitterProfileLink"
              onChange={siteSettingHandleChange}
              value={siteSettingData?.siteTwitterProfileLink}
              id="twitterProfileLink"
              placeholder={`https://www.twitter.com/username`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="tiktokProfileLink"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Tiktok Profile Link
            </label>
            <input
              type="text"
              name="siteTikTokProfileLink"
              id="tiktokProfileLink"
              onChange={siteSettingHandleChange}
              value={siteSettingData?.siteTikTokProfileLink}
              placeholder={`https://www.tiktok.com/username`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>
          <div className="w-11/12">
            <label
              htmlFor="metaKeyWords"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Meta keywords
            </label>
            <input
              type="text"
              name="siteMetaKeywords"
              id="metaKeyWords"
              onChange={siteSettingHandleChange}
              value={siteSettingData?.siteMetaKeywords}
              placeholder={`weave,architect design,...`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="metaKeyWords"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Meta Description
            </label>
            <input
              type="text"
              name="siteMetaDescription"
              id="metaDescription"
              value={siteSettingData?.siteMetaDescription}
              onChange={siteSettingHandleChange}
              placeholder={`meta description of page`}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              //required
            />
          </div>

        

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SiteSettings;
