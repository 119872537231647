import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";



export const postUser = createAsyncThunk(
  "auth/signUp",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/user/create`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification("sign up sucessful", {
          variant: "success",
        });
        params.resetForm();
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      console.log(err);
      params.notification(err.response.data.error, {
        variant: "error",
      });
      return rejectWithValue("Failed to signup!");
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signin`,
        params.formData
      );
      // params.resetForm();
      console.log(response.data);
      if(response.data.sucess){
        params.notification("sign in sucessful", {
          variant: "success",
        });
        params.resetForm();
      return await response.data;

      }else{
        return null;
      }
    } catch (err) {
      console.log(err);
      params.notification(err.response.data.error, {
        variant: "error",
      });
      return rejectWithValue("Failed to sign in!");
    }
  }
);

export const socialSignIn = createAsyncThunk(
  "auth/socialSignIn",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/socialsignin`,
        params.formData
      );
      // params.resetForm();
      console.log(response.data);
      if(response.data.sucess){
        params.notification("sign in sucessful", {
          variant: "success",
        });
        
      return await response.data;

      }else{
        return null;
      }
    } catch (err) {
      console.log(err);
      params.notification(err.response.data.error, {
        variant: "error",
      });
      return rejectWithValue("Failed to sign in!");
    }
  }
);


export const signOut = createAsyncThunk(
  "auth/signOut",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signout`
      );
      console.log(response);
      if (response.data.sucess) {
        params.notification("Sign out sucessful", {
          variant: "success",
        });

        return null;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to signout!", {
        variant: "error",
      });
      return rejectWithValue("Failed to sign out!");
      
    }
  }
);




const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {
      role:`${localStorage.getItem('weave_role')}`,
    token:`${localStorage.getItem('weave_token')}`
    }
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(postUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postUser.fulfilled, (state, action) => {
        
        state.auth=action.payload;
        state.status = "idle";
      })
      .addCase(postUser.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      }) 
      .addCase(signIn.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        
        state.auth.role=action.payload.role;
        state.auth.token=action.payload.token;
        state.status = "idle";
        localStorage.setItem('weave_token',action.payload.token)
        localStorage.setItem('weave_role',action.payload.role)
      })
      .addCase(signIn.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      }) 
      .addCase(socialSignIn.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(socialSignIn.fulfilled, (state, action) => {
        
        state.auth.role=action.payload.role;
        state.auth.token=action.payload.token;
        state.status = "idle";
        localStorage.setItem('weave_token',action.payload.token)
        localStorage.setItem('weave_role',action.payload.role)
      })
      .addCase(socialSignIn.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      }) 
      .addCase(signOut.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(signOut.fulfilled, (state, action) => {
        
        state.auth.role="null";
        state.auth.token="null";
        state.status = "idle";
        localStorage.removeItem('weave_token');
        localStorage.removeItem('weave_role')
      })
      .addCase(signOut.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      }) 
     
      ;
  },
});
export default authSlice;


