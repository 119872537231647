import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getProject = createAsyncThunk(
  "project/getAdminProject",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Project fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch project!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all project!");
      
    }
  }
);

export const postProject = createAsyncThunk(
  "project/postAdminProject",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
        params.resetForm();
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save project ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save project!");
    }
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteAdminProject",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project`,
      {
        data:params.formData
      }
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to delete project ", {
        variant: "error",
      });
      return rejectWithValue("Failed to delete project!");
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState: {
    project: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProject.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.project = action.payload;
        // state.project=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getProject.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      })
      .addCase(postProject.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postProject.fulfilled, (state, action) => {
        // state.project = action.payload;
        // state.project.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.project[state.project.length]=action.payload
        let data=[];
        data = state.project.filter((ser) => ser._id != action.payload._id);
        data.push(action.payload);
        state.project=data;
        state.status = "idle";
      })
      .addCase(postProject.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      }) .addCase(deleteProject.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        // state.project = action.payload;
        // state.project.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.project[state.project.length]=action.payload
        let data=[];
        data = state.project.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        state.project=data;
        state.status = "idle";
      })
      .addCase(deleteProject.rejected, (state, action) => {
        // state.project=action.payload
        state.status = "failed";
      });
      ;
  },
});
export default projectSlice;


