import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getContact = createAsyncThunk(
  "contact/getAdminContact",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/contact`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Contact fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch contact!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all contact!");
      
    }
  }
);

// export const postContact = createAsyncThunk(
//   "contact/postAdminContact",
//   async (params, { rejectWithValue }) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_BASE_URL}/admin/contact`,
//         params.formData
//       );
//       // params.resetForm();
//       console.log(response);
//       if(response.data.sucess){
//         params.notification(response.data.message, {
//           variant: "success",
//         });
//       return await response.data.data;

//       }else{
//         return null;
//       }
//     } catch (err) {
//       // console.log(err);
//       params.notification("Failed to save contact ", {
//         variant: "error",
//       });
//       return rejectWithValue("Failed to save contact!");
//     }
//   }
// );

export const deleteContact = createAsyncThunk(
  "contact/deleteAdminContact",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/admin/contact`,
      {
        data:params.formData
      }
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to delete contact ", {
        variant: "error",
      });
      return rejectWithValue("Failed to delete contact!");
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContact.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.contact = action.payload;
        // state.contact=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getContact.rejected, (state, action) => {
        // state.contact=action.payload
        state.status = "failed";
      })
    //   .addCase(postContact.pending, (state, action) => {
    //     state.status = "loading";
    //   })
    //   .addCase(postContact.fulfilled, (state, action) => {
    //     // state.contact = action.payload;
    //     // state.contact.filter(value=>{
    //     //     if(value._id!==action.payload._id){
    //     //        return ;
    //     //     }
    //     // })
    //     // state.contact[state.contact.length]=action.payload
    //     let data=[];
    //     data = state.contact.filter((ser) => ser._id != action.payload._id);
    //     data.push(action.payload);
    //     state.contact=data;
    //     state.status = "idle";
    //   })
    //   .addCase(postContact.rejected, (state, action) => {
    //     // state.contact=action.payload
    //     state.status = "failed";
    //   })
     .addCase(deleteContact.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        // state.contact = action.payload;
        // state.contact.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.contact[state.contact.length]=action.payload
        let data=[];
        data = state.contact.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        state.contact=data;
        state.status = "idle";
      })
      .addCase(deleteContact.rejected, (state, action) => {
        // state.contact=action.payload
        state.status = "failed";
      });
      ;
  },
});
export default contactSlice;

// extraReducers: {
//     [getContact.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.contact = action.payload;
//     },
//     [getContact.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postContact.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postContact.fulfilled]: (state, action) => {
//         state.contacts = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postContact.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
