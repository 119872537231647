import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import SocialLogin from '../../SocialLogin/socialLogin';
import { signIn } from '../../store/client/auth';
import useCustomNotification from '../../utils/notification';
import LoadingSpinner from '../LoadingSpinner/loadingSpinner';
import { GoogleOAuthProvider } from '@react-oauth/google';


function SignIn() {
  const notification=useCustomNotification();
  const dispatch=useDispatch();

  const {status,auth}=useSelector(state=>state.auth);
  // destructure user data
  // const { title, info, form } = siteData;
//for form data
const [userData,setUserData] = useState({
  email:"",
  password:""
  
});

const userHandleChange = async(e) => {
  
    setUserData({ ...userData, [e.target.name]: e.target.value });
  
};

const resetForm=()=>{
  setUserData({
  
    email:"",
    password:""
    
  });
}
const onHandleSubmit=(e)=>{
e.preventDefault();
const formData=new FormData();

formData.append('email',userData.email);
formData.append('password',userData.password);

dispatch(signIn({formData,notification,resetForm}))

}


if(status=="loading")
{
  return(<>
  <LoadingSpinner/>
  </>)
}
    return ( 
        <div className="flex justify-center mt-0 pt-10 ">
  <div className="p-4 w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6 max-h-min" action="submit" onSubmit={onHandleSubmit}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign in to our platform</h5>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
            <input type="email"
            value={userData.email}
            onChange={userHandleChange}
             name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your password</label>
            <input type="password" name="password"
            value={userData.password}
            onChange={userHandleChange}
            id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          {/* <div className="flex items-start">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input id="remember" type="checkbox" defaultValue className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
              </div>
              <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
            </div>
            <a href="#" className="ml-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
          </div> */}
          <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
          <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
            Not registered? <Link to="/SignUp" className="text-blue-700 hover:underline dark:text-blue-500">Create account</Link>
          </div>
        </form>
        <GoogleOAuthProvider

        clientId={process.env.REACT_APP_GOOGLE_API_OAUTH_CLIENTID}

      >

      <SocialLogin/>
        </GoogleOAuthProvider>
      </div>
      
     
        </div>
      
     );
}

export default SignIn;