

import DashboardSidebar from "./dashboardSidebar";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import OverView from "./overview";
import Users from "./users";
import Products from "./products";
import Orders from "./orders";
import CreateProduct from "./createProduct";
import UserAdminDetails from "./userAdminDetails";
import ProductAdminDetails from "./productAdminDetials";
import OrderAdminDetails from "./orderAdminDetails";
import Stock from "./stock";
import Sales from "./sales";
import Notification from "./notification";
import Inbox from "./inbox";
import SiteSettings from "./siteSetting/siteSetting";
import TermsAndConditons from "./termsAndConditions/termsAndConditions";
import PrivacyPolicy from "./privacyPolicy/privacyPolicy";
import Projects from "./projects/createProject";
import CreateProject from "./projects/createProject";
import ViewProjects from "./projects/viewProjects";
import Banner from "./banner/banner";
import About from "./about/about";
import Services from "./services/services";







function Dashboard() {





  return (  
    <div className=" flex">
      <div className="h-screen sticky  top-12 lg:top-12 " >
      <DashboardSidebar/>
      </div>
    
     
      <div className="w-11/12 dark:bg-gray-700">
      <Routes>
     
        {/* <Route exact path="User/123" element={<UserAdminDetails/>}></Route>
        <Route exact path="Product/123" element={<ProductAdminDetails/>}></Route>
        <Route exact path="Order/123" element={<OrderAdminDetails/>}></Route>
        <Route exact path="Orders" element={<Orders/>}></Route>
        <Route exact path="Stock" element={<Stock/>}></Route>
        <Route exact path="Sales" element={<Sales/>}></Route>
        <Route exact path="Notification" element={<Notification/>}></Route> */}

        // new routes for weave
        <Route exact path="/" element={<OverView/>}></Route>
        <Route exact path="CreateProduct" element={<CreateProduct/>}></Route>
        <Route exact path="sitesettings" element={<SiteSettings/>}></Route>
        <Route exact path="termsandconditions" element={<TermsAndConditons/>}></Route>
        <Route exact path="privacypolicy" element={<PrivacyPolicy/>}></Route>
        <Route exact path="projects" element={<ViewProjects/>}></Route>
        <Route exact path="Inbox" element={<Inbox/>}></Route>
        <Route exact path="createproject" element={<CreateProject/>}></Route>
        <Route exact path="banner" element={<Banner/>}></Route>
        <Route exact path="about" element={<About/>}></Route>
        <Route exact path="services" element={<Services/>}></Route>
        <Route exact path="Products" element={<Products/>}></Route>
        <Route exact path="Users" element={<Users/>}></Route> 



      </Routes>
      </div>
     
    </div>
  );
}

export default Dashboard;
