import { useState } from 'react';
import { Link } from 'react-router-dom';

function PortfolioCard(props) {
  const portfolioData = props.portfolioData;
  // const [isExpanded, setIsExpanded] = useState(false);

  // const toggleExpansion = () => {
  //   setIsExpanded(!isExpanded);
  // };

  // if (isExpanded) {
  //   return (
  //     <div
  //       className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-80"
  //       onClick={toggleExpansion}
  //     >
  //       <div className="flex flex-col h-full max-w-4xl p-4 bg-white rounded-lg shadow-lg">
  //         <div className="flex-1">
  //           <img
  //             src={portfolioData.imageUrl}
  //             alt={portfolioData.name}
  //             className="w-full h-full object-cover rounded-lg"
  //           />
  //         </div>
  //         <div className="flex-1 p-4">
  //           <h3 className="text-2xl font-bold text-gray-700 mb-2">
  //             {portfolioData.name}
  //           </h3>
  //           <p className="text-gray-600">{portfolioData.description}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <Link to={`/project/${portfolioData._id}`}>
      <div className="px-2 py-4 hover:scale-105 hover:transition-all cursor-pointer ">
      {/* <h1 className="text-3xl font-bold text-center">Our Portfolio</h1> */}
      <div className=''>
       
          <div key={portfolioData.id} className="bg-gray-200 rounded-lg shadow-lg overflow-hidden">
            <img src={portfolioData.projectImages[0]} alt={portfolioData.projectName} className="w-full h-44" />
            <div className="p-4">
              <h3 className="text-md font-bold text-gray-700 mb-2 line-clamp-2 ">{portfolioData.projectName}</h3>
              <p
              dangerouslySetInnerHTML={{__html: portfolioData.projectDescription}}
              
              className="text-gray-600 line-clamp-2"></p>
            </div>
          </div>
        </div>
      </div></Link>
    
    </div>
   
  
  );
}

export default PortfolioCard;
