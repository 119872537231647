import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import { data } from "autoprefixer";
import ServicesTable from "../Tables/servicesTable";
import { useDispatch, useSelector } from "react-redux";
// import demoProductImage from "../../demoImages/productDemoImage1.jpg";
import useCustomNotification from "../../../utils/notification";
import { getAbout, postAbout } from "../../../store/admin/about";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";
import { deleteService, getService, postService } from "../../../store/admin/service";

const categoryList = [
  { value: "Electronics", label: "Electronics" },
  { value: "Cameras", label: "Cameras" },
  { value: "Laptops", label: "Laptops" },
  { value: "Accessories", label: "Accessories" },
  { value: "Headphones", label: "Headphones" },
  { value: "Food", label: "Food" },
  { value: "Books", label: "Books" },
  { value: "Clothes/Shoes", label: "clothes/Shooes" },
  { value: "Beauty/Health", label: "Beauty/Health" },
  { value: "Sports", label: "Sports" },
  { value: "Outdoor", label: "Outdoor" },
  { value: "Home", label: "Home" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "w-full",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

function Services() {
  const notification = useCustomNotification();
  const [selectedServiceId, setSelectedServiceId] = useState(undefined);

  const dispatch = useDispatch();
  useEffect(() => {
    // setDataCkEditor(true);
    dispatch(getService({ notification }));
    //  notification("ddddd",{variant:"error"})
  }, []);

  const { status, service } = useSelector((state) => state.adminService);

  // const [editorLoaded, setEditorLoaded] = useState(false);
  const [serviceTitle, setServiceTitle] = useState("");
  const [dataCkEditor, setDataCkEditor] = useState("");

  const handleEdit = (id) => {
    console.log(id);
    for (let i = 0; i < service.length; i++) {
      if (id == service[i]._id) {
        setSelectedServiceId(id);
        setServiceTitle(service[i].serviceTitle);
        setDataCkEditor(service[i].serviceDescription);
        break;
      }
    }
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  const handleDelete=(id)=>{
    console.log(id);
    const formData=new FormData();
    formData.append('_id',id);
    dispatch(deleteService({formData,notification} ));
  }

  const handleSubmitButton = (e) => {
    e.preventDefault();
    e.preventDefault();
    const formData = new FormData();
    formData.append("_id", selectedServiceId);
    formData.append("serviceTitle", serviceTitle ? serviceTitle : "");
    formData.append("serviceDescription", dataCkEditor);
    dispatch(postService({ formData, notification }));
    console.log(dataCkEditor);
  };

  // console.log(siteSettingData);
  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Servies
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="theme"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Service Title
            </label>
            <input
              type="text"
              name="text"
              id="serviceTitle"
              value={serviceTitle}
              onChange={(e) => {
                setServiceTitle(e.target.value);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="service title"
              required
            />
          </div>

          <div className="w-11/12 ">
            <CKeditor
              name="Service Description"
              content={dataCkEditor}
              setContent={setDataCkEditor}
              initialHeight="100px"
              // editorLoaded={editorLoaded}
            />
            {/* {JSON.stringify(dataCkEditor)} */}
          </div>

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <ServicesTable 
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      list={service} />
    </div>
  );
}

export default Services;
