import React from 'react';
import { motion } from 'framer-motion';
import {FaMapMarkerAlt,FaPhoneAlt,FaEnvelope} from 'react-icons/fa'
import Acc from '../../components/pages/Services/Acc';

 const contactData = {
  title: 'Weave-Decor',
  info: [
    {
      title: 'kanlanki branch',
      subtitle:
        'Welcome to Weave Decors, a full-service architectural design firm specializing in areas of expertise, such as residential or commercial architecture, interior design, historic preservation, sustainability, etc. With a passion for excellence and a commitment to exceptional client service, our team of skilled architects and designers is dedicated to bringing your vision to life.',
      address: {
        icon: <FaMapMarkerAlt />,
        name: 'kalanki-4 ,malkalu',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+9779867550355',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'weave@gmail.com',
      },
      link: 'Get location',
    },
    // {
    //   title: 'sankhu office',
    //   subtitle:
    //     'Sit amet, consectetur nun in velit arcu posuere integer. Adipiscing elit duis porttitor massa tellus',
    //   address: {
    //     icon: <FaMapMarkerAlt />,
    //     name: 'sali river ,sankhu',
    //   },
    //   phone: {
    //     icon: <FaPhoneAlt />,
    //     number: '+977 987654321',
    //   },
    //   email: {
    //     icon: <FaEnvelope />,
    //     address: 'weaver-decor@gmail.com',
    //   },
    //   link: 'Get location',
    // },
  ],
  form: {
    name: 'Write your name here',
    email: 'Write your email address',
    message: 'Write your messages here',
    btnText: 'Send it',
  },
};
 const accordions = [
  {
    id: 1,
    title: "What is  Weave-decor?",
  },
  {
    id: 2,
    title: "What are the services?",
  },
  {
    id: 3,
    title: "what are the things?",
  },
  {
    id: 4,
    title: "Why is ayush so handsome?",
  },
];

const Password = () => {
    const { title, info, form } = contactData;
  return (
    <div>
        
<section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-y-16'>
           <motion.div
          
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.4 }}
            className='flex-1 py-[62px] container '
          >
            {/* title */}
            <h2 className='mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white'>{title}</h2>
            {/* info items */}
            <div className='flex flex-col xl:flex-row gap-x-5 gap-y-16 xl:gap-y-0'>
              {info.map((item, index) => {
                // destructure item
                const { title, subtitle, address, phone, email, link } = item;
                return (
                  // item
                  <div key={index}>
                    {/* title */}
                    <div className='font-bond uppercase font-medium text-xl mb-3 dark:text-white'>
                      {title}
                    </div>
                    {/* subtitle */}
                    <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em] dark:text-white'>
                      {subtitle}
                    </div>
                    {/* address, phone & email */}
                    <div className='flex flex-col gap-y-3 mb-8'>
                      {/* address */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{address.icon}</div>
                        <div className='font-medium'>{address.name}</div>
                      </div>
                      {/* phone */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{phone.icon}</div>
                        <div className='font-medium'>{phone.number}</div>
                      </div>
                      {/* email */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{email.icon}</div>
                        <div className='font-medium'>{email.address}</div>
                      </div>
                    </div>
                    {/* link */}
                    

<h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Password requirements:</h2>
<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        At least 10 characters
    </li>
    <li class="flex items-center">
        <svg class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        At least one lowercase character
    </li>
    <li class="flex items-center">
        <svg class="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
        At least one special character, e.g., ! @ # ?
    </li>
</ul>

                  </div>
                  
                );
              })}
            </div>
          </motion.div>
             {/* form */}
             <motion.div
            
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.4 }}
            className='flex-1 xl:pl-[40px] flex justify-center items-center '
          >
            <section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Enter email and phone number to  recover your account
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required/>
                  </div>
                  <div>
            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label>
            <input type="tel" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="+977 987654321" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                     </div>
                  <div class="flex items-center justify-between">
                      

                  </div>
                  <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send</button>
                 
              </form>
          </div>
      </div>
  </div>
</section>
          </motion.div>
          {/* text */}
         
       
        </div>
        <div className="mt-12  p-1 w-full mx-auto">
        {accordions.map((accordion) => {
          return <Acc key={accordion.id} {...accordion} />;
        })}
      </div>
      </div>
    </section>


    </div>
  )
}

export default Password