import React, { useState } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const DarkModeToggle = (props) => {
  const [viewMode, setViewMode] = useState(props.viewMode);

  const handleViewModeChange = (e) => {
    if (viewMode === 'light') {
      setViewMode('dark');
    } else {
      setViewMode('light');
    }
  };
  const toggleDarkMode=(e)=>{
    if(e.target.value=="light"){
      setViewMode('dark');
    }else{
      setViewMode("light");
    }
    }

  return (
    <button onClick={handleViewModeChange} onChange={toggleDarkMode}
     className="text-white mr-0 focus:outline-none hover:text-yellow-400">
      {viewMode === 'light'
      ? 
      (
        <FaSun size={20} className="inline-block h-5 w-5" />
      ) : 
      (
        <FaMoon size={20} className="inline-block h-5 w-5" />
      )}
    </button>
  );
};

export default DarkModeToggle;
