import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
axios.defaults.withCredentials = true;
// import notification from "../../components/notification";

export const getUser = createAsyncThunk(
  "user/getAdminUser",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/admin/users`
      );
      console.log(response);
      if (response.data.sucess) {
        params.notification("User fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      params.notification("Failed to fetch user!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all user!");
    }
  }
);

export const updateUserRole = createAsyncThunk(
  "user/updateUserRole",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/admin/user/update/role/${params.selectedUserId}`
        ,
        
      );
      // params.resetForm();
      console.log(response);
      if (response.data.sucess) {
        params.notification(response.data.message, {
          variant: "success",
        });
        return await response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification(err.response.data.error, {
        variant: "error",
      });
      return rejectWithValue("Failed to delete user!");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload;
        // state.user=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getUser.rejected, (state, action) => {
        // state.user=action.payload
        state.status = "failed";
      })
      .addCase(updateUserRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        // state.user = action.payload;
        // state.user.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.user[state.user.length]=action.payload
        let data = [];
        data = state.user.filter((ser) => ser._id != action.payload._id);
        data.push(action.payload);
        state.user = data;
        state.status = "idle";
      })
      .addCase(updateUserRole.rejected, (state, action) => {
        // state.user=action.payload
        state.status = "failed";
      });
    //   .addCase(deleteUser.pending, (state, action) => {
    //     state.status = "loading";
    //   })
    //   .addCase(deleteUser.fulfilled, (state, action) => {
    //     // state.user = action.payload;
    //     // state.user.filter(value=>{
    //     //     if(value._id!==action.payload._id){
    //     //        return ;
    //     //     }
    //     // })
    //     // state.user[state.user.length]=action.payload
    //     let data=[];
    //     data = state.user.filter((ser) => ser._id != action.payload._id);
    //     // data.push(action.payload);
    //     state.user=data;
    //     state.status = "idle";
    //   })
    //   .addCase(deleteUser.rejected, (state, action) => {
    //     // state.user=action.payload
    //     state.status = "failed";
    //   });
  },
});
export default userSlice;
