import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import demoProjectImage from "../../../demoImages/banner1.jpg"
const  categoryList= [
  'Electronics',
  'Cameras',
  'Laptops',
  'Accessories',
  'Headphones',
  'Food',
  "Books",
  'Clothes/Shoes',
  'Beauty/Health',
  'Sports',
  'Outdoor',
  'Home'
]

function QuestionAnswersTable(props) {
  const listLimit=4;
  const[startIndex,setStartIndex]=useState(0);
  const {list,handleEdit,handleDelete}=props;

  const[listIndex,setListIndex]=useState(4);

  const changeListIndex=(count)=>{
    if(count<0){
      let start=startIndex+count;
      if(start<0){
        setStartIndex(0)
        setListIndex(listLimit);
      }else{
        setStartIndex(start);
        setListIndex(listIndex+count)
      }
    
    }
// console.log(listIndex,count,list.length);

    if(count>0 ){
       let last=listIndex+count;
       if(last>list.length){
        setListIndex(list.length);
        setStartIndex(list.length-listLimit);
       }else{
        setStartIndex(startIndex+count);
        setListIndex(last);
       
       }
       
    }

    // console.log(listIndex);
  }

  useEffect(()=>{
// console.log(listIndex);
  },[listIndex,startIndex])

  

  // console.log(list);
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      {/* table component */}
      <div className="container mx-auto px-4 sm:px-8 dark:bg-gray-700 dark:border-gray-700">
        <div className="py-8">
          <div>
            <h2 className="text-2xl font-semibold leading-tight dark:text-white">
              Question Answer
            </h2>
          </div>
         
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:bg-gray-800 dark:border-gray-100 dark:text-white ">
                      Question
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:bg-gray-800 dark:border-gray-100 dark:text-white ">
                      Type
                    </th>
                    <th className="px-5 py-3  border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider  dark:bg-gray-800 dark:border-gray-100 dark:text-white">
                    Answer
                    </th>
                    {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider  dark:bg-gray-800 dark:border-gray-100 dark:text-white">
                    Created At
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider  dark:bg-gray-800 dark:border-gray-100 dark:text-white">
                    Updated At
                    </th> */}

                  
                   
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider  dark:bg-gray-800 dark:border-gray-100 dark:text-white">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                 
                 {
                  list && list.map((item,index)=>{
                    if(index>=listIndex || index<startIndex){
                      return<></>
                    }
                    return ( <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex items-center">
                          
                          <div className="ml-3">
                            <div
                            className=" font-medium text-gray-900 whitespace-no-wrap  dark:text-white">
                              {item.question}
                            </div>
                            {/* <p className="text-gray-700 whitespace-no-wrap  dark:text-white">
                              Samsung
                            </p> */}
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex items-center">
                          
                          <div className="ml-3">
                            <div
                            className=" font-medium text-gray-900 whitespace-no-wrap  dark:text-white">
                              {item.questionType}
                            </div>
                            {/* <p className="text-gray-700 whitespace-no-wrap  dark:text-white">
                              Samsung
                            </p> */}
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700 ">
                        <p 
                            dangerouslySetInnerHTML={{__html: `${item.answer}`}}
                        
                        className="text-gray-900 whitespace-no-wrap dark:text-white break-words line-clamp-4">
                          {/* {item.answer} */}
                        </p>
                      </td>
                     
                      {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                          2858-1-1
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700 ">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                          2058-8-6 
                        </p>
                      </td> */}
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm  dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex items-start  gap-2">
                        <div
                        onClick={e=>handleEdit(item._id)}  className="cursor-pointer font-medium mx-2 whitespace-no-wrap  text-blue-700 hover:underline dark:text-blue-500">
                          Edit
                        </div>
                        <div
                           onClick={e=>handleDelete(item._id)}
                          className="cursor-pointer font-medium mx-2 whitespace-no-wrap  text-red-700 hover:underline dark:text-red-500">
                          Delete
                        </div>
                        </div>
                        
                        
                      </td>
                    </tr>)
                  })
                 }
                 
                 
                 
                 

                 
                 
                  {/* <tr>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className= " font-medium text-gray-900 whitespace-no-wrap">
                          Vera Carpenter
                        </p>
                        <p className="text-gray-700 whitespace-no-wrap">
                          vera@gmail.com
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">Admin</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      Jan 21, 2020
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
                      <span className="relative">Activo</span>
                    </span>
                  </td>
                </tr>
                
                <tr>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-gray-900 whitespace-no-wrap">
                          Blake Bowman
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">Editor</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      Jan 01, 2020
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
                      <span className="relative">Activo</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1540845511934-7721dd7adec3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-gray-900 whitespace-no-wrap">
                          Dana Moore
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">Editor</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      Jan 10, 2020
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight">
                      <span aria-hidden className="absolute inset-0 bg-orange-200 opacity-50 rounded-full" />
                      <span className="relative">Suspended</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="px-5 py-5 bg-white text-sm">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img className="w-full h-full rounded-full" src="https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&h=160&w=160&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-gray-900 whitespace-no-wrap">
                          Alonzo Cox
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-5 py-5 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">Admin</p>
                  </td>
                  <td className="px-5 py-5 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">Jan 18, 2020</p>
                  </td>
                  <td className="px-5 py-5 bg-white text-sm">
                    <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                      <span aria-hidden className="absolute inset-0 bg-red-200 opacity-50 rounded-full" />
                      <span className="relative">Inactive</span>
                    </span>
                  </td>
                </tr> */}
                </tbody>
              </table>
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between  dark:bg-gray-800 dark:border-gray-700      ">
                <span className="text-xs xs:text-sm text-gray-900 dark:text-white">
                  Showing  {startIndex+1} to {listIndex} of {list.length} Entries
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button 
                     onClick={e=>changeListIndex(-listLimit)}
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l dark">
                    Prev
                  </button>
                  <button 
                  onClick={e=>changeListIndex(+listLimit)}
                  
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionAnswersTable;
