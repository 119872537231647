import React from 'react';

function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
    <h1 className="text-4xl md:text-6xl font-bold mb-4 md:mb-8">404</h1>
    <p className="text-lg md:text-2xl mb-4 md:mb-8 text-center">
      Oops! The page you are looking for cannot be found.
    </p>
    <span className="text-5xl md:text-9xl" role="img" aria-label="sad-face">
      😞
    </span>
    <p className='flex '>  check the link once again</p>
  </div>
  );
}

export default NotFound;
