import { useEffect, useState } from "react";
import noImageLogo from "../../../assests/noImage.png";
import Select from "react-select";
import CKEditorConponent from "../../ckEditor/ckEditor";
import CKeditor from "../../ckEditor/ckEditor";
import useCustomNotification from "../../../utils/notification";
import { getTermsAndConditions, postTermsAndConditions } from "../../../store/admin/termsAndConditions";
import LoadingSpinner from "../../LoadingSpinner/loadingSpinner";
import { useDispatch, useSelector } from "react-redux";

function TermsAndConditons() {
  const notification=useCustomNotification();
const dispatch=useDispatch();
  const [dataCkEditor, setDataCkEditor] = useState("");
  const {termsAndConditions,status}=useSelector(state=>state.adminTermsAndConditions);

  useEffect(()=>{
  dispatch(getTermsAndConditions({notification}));
  },[])
  
  useEffect(()=>{
    if(termsAndConditions && termsAndConditions.details){
      // console.log(privacyPolicy);
      setDataCkEditor(termsAndConditions.details);
  
    }
  },[termsAndConditions])
    
  
  
    const handleSubmitButton=(e)=>{
      e.preventDefault();
      const formData=new FormData();
      formData.append('_id',termsAndConditions?._id);
      formData.append('details',dataCkEditor);
      dispatch(postTermsAndConditions({formData,notification}))
      // console.log(dataCkEditor);
    }
  
  
  
  
    if(status=="loading")
  {
    return(<>
    <LoadingSpinner/>
    </>)
  }
  

  
    return ( <div>
         <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Terms and Condtions
          </h5>
          

          
          <div className="w-11/12"  >
           
              <CKeditor
              name="Terms and Condtions"
               
              content={dataCkEditor}
                setContent={setDataCkEditor}
                initialHeight="50vh"
                // editorLoaded={editorLoaded}
              />
              {/* {JSON.stringify(dataCkEditor)} */}
           
          </div>

         

         
         

        

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div> );
}

export default TermsAndConditons;