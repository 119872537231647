import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../store/client/privacyPolicy";

function PrivacyPolicy() {
  const {privacyPolicy}=useSelector(state=>state.clientPrivacyPolicy);

  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(getPrivacyPolicy());
// dispatch(getSiteSetting());


  },[])
  return (
       // </div>
<div className="ml-5 mr-5 py-4">
<div dangerouslySetInnerHTML={{__html: privacyPolicy?.details}} />

</div>
  );
}

export default PrivacyPolicy;
