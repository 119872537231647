import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getProject = createAsyncThunk(
    "project/getClientProject",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/project`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all project!");
        }
    })

const projectSlice = createSlice({
    name: "project",
    initialState: {
        project:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getProject.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getProject.fulfilled, (state, action) => {
            state.project=action.payload
            state.status = 'idle'
          }) .addCase(getProject.rejected, (state, action) => {
            // state.project=action.payload
            state.status = 'failed'
          })
      }
    
})
export default projectSlice;

// extraReducers: {
//     [getProject.fulfilled]: (state, { payload }) => {
//         state.project = payload;
//     },
// }