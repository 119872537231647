import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../store/client/project';
import PortfolioCard from '../portfolioCard/portfolioCard';
const items = [
  {
    id: 1,
    name: 'Living Room',
    imageUrl:'https://images.unsplash.com/photo-1561815908-bb4926a2f4eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTR8fGJ1aWxkaW5nJTIwaW1hZ2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'
   ,
    description: 'A cozy and modern living room design'
  },
  {
    id: 2,
    name: 'Kitchen',
    imageUrl: 'https://images.unsplash.com/photo-1542228227152-511d6b6bbe1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxidWlsZGluZyUyMGltYWdlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    description: 'A sleek and functional kitchen design'
  },
  {
    id: 3,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1610016302534-6f67f1c968d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fGJ1aWxkaW5nJTIwaW1hZ2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
  {
    id: 4,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1561815908-bb4926a2f4eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTR8fGJ1aWxkaW5nJTIwaW1hZ2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
  {
    id: 5,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1542228227152-511d6b6bbe1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxidWlsZGluZyUyMGltYWdlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
  {
    id: 6,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1610016302534-6f67f1c968d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fGJ1aWxkaW5nJTIwaW1hZ2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
  {
    id: 7,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1542228227152-511d6b6bbe1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxidWlsZGluZyUyMGltYWdlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
  {
    id: 8,
    name: 'Bedroom',
   imageUrl:'https://images.unsplash.com/photo-1610016302534-6f67f1c968d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fGJ1aWxkaW5nJTIwaW1hZ2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
    description: 'A calming and stylish bedroom design'
  },
];

function Portfolio() {
 
  const {project}=useSelector(state=>state.clientProject);


  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(getProject());

  },[])

  return (
    <div>
      <h2 className='text-4xl justify-center text-center  pt-3'> Check out our <span className='text-5xl  underline text-blue-600 dark:text-blue-400'>Portfolio</span></h2>

    <div className="xxs:m-0 xs:m-0 sm:m-6 mt-0 grid grid-cols-1 gap-1     xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
     
    {project?.map((portfolioData, index) => {
          return <PortfolioCard key={index} portfolioData={portfolioData} />;
        })}
  </div>
  </div>
    // <div className="px-4 py-6">
    //   <h1 className="text-3xl font-bold text-center">Our Portfolio</h1>
    //   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
    //     {items.map(item => (
    //       <div key={item.id} className="bg-gray-200 rounded-lg shadow-lg overflow-hidden">
    //         <img src={item.imageUrl} alt={item.name} className="w-full" />
    //         <div className="p-4">
    //           <h3 className="text-xl font-bold text-gray-700 mb-2">{item.name}</h3>
    //           <p className="text-gray-600">{item.description}</p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}

export default Portfolio;
