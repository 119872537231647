import React, { useState } from 'react'
// import { data } from './datum.js'
import { motion, AnimatePresence } from "framer-motion"
import {AiOutlineFullscreenExit} from 'react-icons/ai'
import {BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill} from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

let data=[]
const Food = () => {

  const {product}=useSelector(state=>state.clientProduct);

    const [selectedItem, setSelectedItem] = useState(null);
    const [foods, setFoods] = useState([]);
    const [thumbnail,setthumbnail]=useState(0);
    //this is new
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    //this is brand new
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
    const handleThumbnailClick = (index) => {
      setSelectedItem({ ...selectedItem, image: selectedItem.thumbnailImages[index] });
      setSelectedThumbnailIndex(index);
    };
  
    const handleNextThumbnailClick = () => {
      setSelectedThumbnailIndex((selectedThumbnailIndex + 1) % selectedItem.thumbnailImages.length);
      setSelectedItem({ ...selectedItem, image: selectedItem.thumbnailImages[(selectedThumbnailIndex + 1) % selectedItem.thumbnailImages.length] });
    };
  
    const handlePreviousThumbnailClick = () => {
      setSelectedThumbnailIndex((selectedThumbnailIndex + selectedItem.thumbnailImages.length - 1) % selectedItem.thumbnailImages.length);
      setSelectedItem({ ...selectedItem, image: selectedItem.thumbnailImages[(selectedThumbnailIndex + selectedItem.thumbnailImages.length - 1) % selectedItem.thumbnailImages.length] });
    };
    //lol
    //this is  feb 24
    const [activeButton, setActiveButton] = useState(null);

    //this is also new function
    const handleThumbnailLoop = (step) => {
      let newIndex = thumbnailIndex + step;
      if (newIndex < 0) {
        newIndex = selectedItem.thumbnailImages.length - 1;
      } else if (newIndex >= selectedItem.thumbnailImages.length) {
        newIndex = 0;
      }
      setThumbnailIndex(newIndex);
    };

    const filterType = (category) => {
        setFoods(
            data.filter((item) => {
              if(category=="others"){
                if(item.category!=='Building' && item.category!=='Kitchen'&& item.category!=='Architect' && item.category!=='Design' )
                return item;
              }
              console.log(item);
                return item.category === category;
            })
        )
    }
    const filterPrice = (price) => {
        setFoods(
            data.filter((item) => {
                return item.price === price;
            })
        )
    }

    useEffect(()=>{
     data=[];
product?.map(item=>{
data.push(

  {
    id: item._id,
    name: item.productName,
    category: item.productCategory,
    image:item.productImages[0],
  // price: '$$$',
  description:item.productDescription,
  thumbnailImages:item.productImages
  },
);
})

setFoods(data);

    },[product])

    useEffect(()=>{
console.log(foods);
console.log(data,"dd");
    },[foods])

    return (
        <div className='max-w-[1240px] m-auto px-4 py-12'>
            <h1 className='text-red-900 font-bold text-4xl text-center'>
                Our Products
            </h1>
            <div className='flex flex-col lg:flex-row justify-between'>
                {/* Filter Type */}
                <div>
                    <p className='font-bold text-gray-700'>Filter By Category</p>
                    <div className='flex justify-between flex-wrap'>
                        <button onClick={() => { setActiveButton('All'); setFoods(data)}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'All' ? 'bg-orange-600 text-white' : ''}`}>All</button>
                        <button onClick={() =>  {setActiveButton('BedRoom'); filterType('Design')}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'Design' ? 'bg-orange-600 text-white'  : ''}`}>Design</button>
                        <button onClick={() =>  {setActiveButton('Home Office');filterType('Building')}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'Building' ? 'bg-orange-600 text-white' : ''}`}>Building</button>
                        <button onClick={() =>   {setActiveButton('Living-Room');filterType('Kitchen')}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'Kitchen' ? 'bg-orange-600 text-white' : ''}`}>Kitchen</button>
                        <button onClick={() =>  {setActiveButton('Kitchen');filterType('Architect')}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'Architect' ? 'bg-orange-600 text-white' : ''}`}>Architect</button>
                        <button onClick={() =>  {setActiveButton('others');filterType('others')}} className={`m-1 border-orange-600  hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1 ${activeButton === 'others' ? 'bg-orange-600 text-white' : ''}`}>others</button>

                    </div>
                </div>

                {/* Filter Price */}
                {/* <div>
                    <p className='font-bold text-gray-700'>Filter Price</p>
                    <div className='flex justify-between flex-wrap max-w-[390px] w-full'>
                        <button onClick={() => filterPrice('$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$</button>
                        <button onClick={() => filterPrice('$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$$</button>
                        <button onClick={() => filterPrice('$$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$$$</button>
                        <button onClick={() => filterPrice('$$$$')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$$$$</button>
                    </div>
                </div> */}
            </div>

            {/* diplay food */}
            {/* <AnimatePresence>
                <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
                    {foods.map((item, index) => (
                        <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.1 }}
                            key={item.id}
                            className="border shadow-lg rounded-lg hover:scale-105 duration-500 cursor-pointer">
                            <img className='w-full h-[150px] md:h-[200px] object-cover rounded-t-lg' src={item.image} alt={item.name} />
                            <div className='flex justify-between px-2 py-4'>
                                <p>{item.name}</p>
                                <p>
                                    <span className='bg-orange-500 text-white p-1 rounded-md'>{item.price}</span>
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </AnimatePresence> */}
            <AnimatePresence>
  <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
    {foods.map((item, index) => (
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        key={item.id}
        className="border shadow-lg rounded-lg hover:scale-105 duration-500 cursor-pointer"
        onClick={() => setSelectedItem(item)}
      >
        <img
          className='w-full h-[150px] md:h-[200px] object-cover rounded-t-lg'
          src={item.image}
          alt={item.name}
        />
        <div className='flex justify-between px-2 py-4'>
          <p>{item.name}</p>
          <p>
            {/* <span className='bg-orange-500 text-white p-1 rounded-md'>
              {item.price}
            </span> */}
          </p>
        </div>
      </motion.div>
    ))}

{selectedItem && (
  <motion.div
  className="fixed top-0 left-0 w-full h-full bg-white overflow-auto"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={{ duration: 0.1 }}
>
  <button 
    onClick={() => setSelectedItem(null)}
    className="absolute top-12 dark:text-white dark:bg-black right-0 md:right-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    <AiOutlineFullscreenExit size={30}/>
  </button>
  
  <motion.div className="flex h-full flex-col md:flex-row">
    <motion.img
      src={selectedItem.image}
      alt={selectedItem.name}
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      exit={{ x: 100 }}
      transition={{ duration: 0.3 }}
      className="w-full md:w-1/2 h-80 md:h-full object-cover "
    />
    {/* arrow back */}
    <button 
      //  onClick={() => setSelectedItem({ ...selectedItem, selectedImageIndex: (selectedItem.selectedImageIndex - 1 + selectedItem.thumbnailImages.length) % selectedItem.thumbnailImages.length })}
      // what the fuck  is above code
      onClick={handlePreviousThumbnailClick}

        className="absolute top-44 md:top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
     <BsFillArrowLeftCircleFill/>
      </button>
      {/* arrow front */}
       <button 
       onClick={handleNextThumbnailClick}
      className="absolute top-44 md:top-1/2 md:right-1/2 right-0 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      <BsFillArrowRightCircleFill/>
    </button>
   
        
      
    {/* lol */}
    <motion.div
      className="w-full md:w-1/2 p-4 flex flex-col justify-between dark:bg-black"
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      transition={{ duration: 0.3 }}
    >
      <div>
        <h2 className="text-4xl text-center  dark:text-white py-10 font-bold mb-2">{selectedItem.name}</h2>
        <p 
         dangerouslySetInnerHTML={{__html: selectedItem.description}}
        className="text-lg  justify-start text-justify"></p>
        {/* <p className="text-lg font-bold">{selectedItem.price}</p> */}
      </div>
      <div className="flex flex-row mt-4 md:mt-[25%] ">
        {selectedItem.thumbnailImages.map((thumbnail, index) => (
          <motion.img
            key={index}
            src={thumbnail}
            alt={`${selectedItem.name} thumbnail ${index}`}
            // className="w-1/4 h-16 object-cover mx-2 border shadow-md border-gray-300 rounded cursor-pointer "
            className={`w-16 h-16 object-cover mx-2 border shadow-md border-gray-300 rounded cursor-pointer ${
              index === thumbnailIndex && 'border-blue-500'
            }`}
            // onClick={() => setSelectedItem({ ...selectedItem, image: thumbnail })}
            //this is new function
            onClick={() => handleThumbnailClick(index)}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.3, delay: 0.1 * index }}
          />
        ))}
         {/* thumbnail loop buttons */}
         <div className="flex justify-center mt-4">
          {/* <button
            className="border-2 border-gray-300 px-3 py-1 rounded-md mr-2"
            onClick={handleNextThumbnailClick}
          >
            Previous
          </button> */}
          {/* <button
            className="border-2 border-gray-300 px-3 py-1 rounded-md"
            onClick={handlePreviousThumbnailClick}
          >
            Next
          </button> */}
        </div>
      </div>
    </motion.div>
  </motion.div>
</motion.div>
)}


  
  </div>
</AnimatePresence>

        </div>
    )
}

export default Food