import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getProduct = createAsyncThunk(
  "product/getAdminProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/product`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Product fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch product!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all product!");
      
    }
  }
);

export const postProduct = createAsyncThunk(
  "product/postAdminProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/product`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
        params.resetForm();
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save product ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save product!");
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteAdminProduct",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/admin/product`,
      {
        data:params.formData
      }
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to delete product ", {
        variant: "error",
      });
      return rejectWithValue("Failed to delete product!");
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    product: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload;
        // state.product=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getProduct.rejected, (state, action) => {
        // state.product=action.payload
        state.status = "failed";
      })
      .addCase(postProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postProduct.fulfilled, (state, action) => {
        // state.product = action.payload;
        // state.product.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.product[state.product.length]=action.payload
        let data=[];
        data = state.product.filter((ser) => ser._id != action.payload._id);
        data.push(action.payload);
        state.product=data;
        state.status = "idle";
      })
      .addCase(postProduct.rejected, (state, action) => {
        // state.product=action.payload
        state.status = "failed";
      }) .addCase(deleteProduct.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        // state.product = action.payload;
        // state.product.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.product[state.product.length]=action.payload
        let data=[];
        data = state.product.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        state.product=data;
        state.status = "idle";
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        // state.product=action.payload
        state.status = "failed";
      });
      ;
  },
});
export default productSlice;


