import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getAbout = createAsyncThunk(
  "about/getAdminAbout",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/about`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("About fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch about!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all about!");
      
    }
  }
);

export const postAbout = createAsyncThunk(
  "about/postAdminAbout",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/about`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification("About Saved Sucessfully", {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save site Settings", {
        variant: "error",
      });
      return rejectWithValue("Failed to save Site settings!");
    }
  }
);

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    about: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAbout.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAbout.fulfilled, (state, action) => {
        state.about = action.payload;
        state.status = "idle";
      })
      .addCase(getAbout.rejected, (state, action) => {
        // state.about=action.payload
        state.status = "failed";
      })
      .addCase(postAbout.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postAbout.fulfilled, (state, action) => {
        state.about = action.payload;
        state.status = "idle";
      })
      .addCase(postAbout.rejected, (state, action) => {
        // state.about=action.payload
        state.status = "failed";
      });
  },
});
export default aboutSlice;

// extraReducers: {
//     [getAbout.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.about = action.payload;
//     },
//     [getAbout.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postAbout.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postAbout.fulfilled]: (state, action) => {
//         state.abouts = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postAbout.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
