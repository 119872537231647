export const calculateTimeGap = (postedDate) => {
    let secondBetweenTwoDate = Math.abs(
      (new Date().getTime() - new Date(postedDate).getTime()) / 1000
    );
    console.log(secondBetweenTwoDate);
    let time = parseInt(secondBetweenTwoDate).toString().concat(" seconds");

    if (secondBetweenTwoDate > 3600 * 60 * 24 * 30 * 12) {
      secondBetweenTwoDate = parseInt(
        secondBetweenTwoDate / (3600 * 24 * 30 * 12)
      );
      time = secondBetweenTwoDate.toString().concat(" years");
    } else if (secondBetweenTwoDate > 3600 * 24 * 30) {
      secondBetweenTwoDate = parseInt(secondBetweenTwoDate / (3600 * 24 * 30));
      time = secondBetweenTwoDate.toString().concat(" months");
    } else if (secondBetweenTwoDate > 3600 * 24) {
      secondBetweenTwoDate = parseInt(secondBetweenTwoDate / (3600 * 24));
      time = secondBetweenTwoDate.toString().concat(" days");
    } else if (secondBetweenTwoDate > 3600) {
      secondBetweenTwoDate = parseInt(secondBetweenTwoDate / 3600);
      time = secondBetweenTwoDate.toString().concat(" hours");
    } else if (secondBetweenTwoDate > 3600) {
      console.log("done2");
      secondBetweenTwoDate = parseInt(secondBetweenTwoDate / 3600);
      time = secondBetweenTwoDate.toString().concat(" hours");
    } else if (secondBetweenTwoDate > 60) {
      // console.log("Done");
      secondBetweenTwoDate = parseInt(secondBetweenTwoDate / 60);
      time = secondBetweenTwoDate.toString().concat(" minutes");
    }

    return time;
  };