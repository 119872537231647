import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getService = createAsyncThunk(
  "service/getAdminService",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/service`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Service fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch service!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all service!");
      
    }
  }
);

export const postService = createAsyncThunk(
  "service/postAdminService",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/service`,
        params.formData
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save service ", {
        variant: "error",
      });
      return rejectWithValue("Failed to save service!");
    }
  }
);

export const deleteService = createAsyncThunk(
  "service/deleteAdminService",
  async (params, { rejectWithValue }) => {
    console.log(params);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/admin/service`,
      {
        data:params.formData
      }
      );
      // params.resetForm();
      console.log(response);
      if(response.data.sucess){
        params.notification(response.data.message, {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to delete service ", {
        variant: "error",
      });
      return rejectWithValue("Failed to delete service!");
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    service: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getService.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.service = action.payload;
        // state.service=[...state,action.payload]
        state.status = "idle";
      })
      .addCase(getService.rejected, (state, action) => {
        // state.service=action.payload
        state.status = "failed";
      })
      .addCase(postService.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postService.fulfilled, (state, action) => {
        // state.service = action.payload;
        // state.service.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.service[state.service.length]=action.payload
        let data=[];
        data = state.service.filter((ser) => ser._id != action.payload._id);
        data.push(action.payload);
        state.service=data;
        state.status = "idle";
      })
      .addCase(postService.rejected, (state, action) => {
        // state.service=action.payload
        state.status = "failed";
      }) .addCase(deleteService.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        // state.service = action.payload;
        // state.service.filter(value=>{
        //     if(value._id!==action.payload._id){
        //        return ;
        //     }
        // })
        // state.service[state.service.length]=action.payload
        let data=[];
        data = state.service.filter((ser) => ser._id != action.payload._id);
        // data.push(action.payload);
        state.service=data;
        state.status = "idle";
      })
      .addCase(deleteService.rejected, (state, action) => {
        // state.service=action.payload
        state.status = "failed";
      });
      ;
  },
});
export default serviceSlice;

// extraReducers: {
//     [getService.fulfilled]: (state, action) => {
//         // console.log(state);
//         state.service = action.payload;
//     },
//     [getService.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Cannot fetch site settings!" })
//     },
//     [postService.pending]: (state) => {
//         // notification("info", { message: "Working on it!" });
//     },
//     [postService.fulfilled]: (state, action) => {
//         state.services = action.payload;
//         // notification("success", { message: "Success.", description: "Site settings saved successfully." });
//     },
//     [postService.rejected]: (state) => {
//         // notification("error", { message: "Error!", description: "Server error or validation error! " });
//     },
// }
