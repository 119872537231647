import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';

import "./index.css";
import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Fragment } from "react";
import * as serviceWorker from './serviceWorker';

// import notification from "./utils/notification";

// hash router is used instead of browser router as gh-pages doesnot
// support BrowserRouter

function ScrollToTop({ children }) {
  let location = useLocation();


  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
// serviceWorker.register();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router >
      <ScrollToTop />

      <SnackbarProvider>
      <App />
      
        
      

      </SnackbarProvider>

    </Router>
    
    </Provider>
   
  </React.StrictMode>
);
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
