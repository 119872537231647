import React from 'react'
import { Flowbite ,Accordion } from 'flowbite-react';
import {HiOutlineArrowCircleDown} from 'react-icons/hi'
import ContactUs from '../../Contact/ContactUs';
import { getAbout } from '../../store/client/about';
import { getSiteSetting } from '../../store/client/siteSetting';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getQuestionAnswer } from '../../store/client/questionAnswer';


const About = () => {

  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(getAbout());
dispatch(getQuestionAnswer())
// dispatch(getSiteSetting());


  },[])
  return (
    <div>
      
   
   


   
<ContactUs/>
   
    </div>
  );
};

export default About