import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import notification from "../../components/notification";

export const getPrivacyPolicy = createAsyncThunk(
  "privacyPolicy/getAdminPrivacyPolicy",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/privacypolicy`
      );
      // console.log(response);
      if (response.data.sucess) {
        params.notification("Privacy Policy fetched Sucessfully", {
          variant: "success",
        });

        return response.data.data;
      } else {
        return null;
      }
    } catch (err) {
      params.notification("Failed to fetch privacy Policy!", {
        variant: "error",
      });
      return rejectWithValue("Failed to get all privacyPolicy!");
      
    }
  }
);

export const postPrivacyPolicy = createAsyncThunk(
  "privacyPolicy/postAdminPrivacyPolicy",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/privacypolicy`,
        params.formData
      );
      // params.resetForm();
      // console.log(response);
      if(response.data.sucess){
        params.notification("Privacy Policy Saved Sucessfully", {
          variant: "success",
        });
      return await response.data.data;

      }else{
        return null;
      }
    } catch (err) {
      // console.log(err);
      params.notification("Failed to save privacy policy", {
        variant: "error",
      });
      return rejectWithValue("Failed to save privacy policy!");
    }
  }
);

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState: {
    privacyPolicy: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyPolicy.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
        state.privacyPolicy = action.payload;
        state.status = "idle";
      })
      .addCase(getPrivacyPolicy.rejected, (state, action) => {
        // state.privacyPolicy=action.payload
        state.status = "failed";
      })
      .addCase(postPrivacyPolicy.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postPrivacyPolicy.fulfilled, (state, action) => {
        state.privacyPolicy = action.payload;
        state.status = "idle";
      })
      .addCase(postPrivacyPolicy.rejected, (state, action) => {
        // state.privacyPolicy=action.payload
        state.status = "failed";
      });
  },
});
export default privacyPolicySlice;


