import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact, getContact } from "../../store/admin/contact";
import useCustomNotification from "../../utils/notification";
import ConfrimationModal from "../confirmationModal/confirmationModal";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import {calculateTimeGap} from '../../utils/time'

function Inbox() {
  const notification = useCustomNotification();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContact({ notification }));
  }, []);

  const [inboxDeleteId,setInboxDeleteId]=useState(undefined);

  const { contact, status } = useSelector((state) => state.adminContact);

  const [deleteModalState, setDeleteModalState] = useState(false);

  const onDeleteButtonClick = (id) => {
    setDeleteModalState(true);
    setInboxDeleteId(id)
    console.log("dd");
  };

  const confirmDelete = () => {
    console.log("delete confirm");
    const formData=new FormData();
    formData.append('_id',inboxDeleteId);

    dispatch(deleteContact({formData,notification}));
    setInboxDeleteId(undefined)
    setDeleteModalState(false);
  };
  const cancelDelete = () => {
    console.log("delete cancel");
    setDeleteModalState(false);
  };

  useEffect(() => {}, [deleteModalState,contact]);

  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <div className="dark:bg-gray-700 dark:text-white ">
        <div className="flex items-center justify-between dark:bg-gray-800 dark:text-white pt-2 pb-2 "></div>

        {contact &&
          contact.map((inbox) => {
            return (
              <div
              key={inbox._id}
              className="w-full p-0 xs:p-3  border-t bg-white rounded flex dark:bg-gray-800 dark:text-white">
                <div className="pl-0 xs:pl-3  w-full">
                  <p className="text-md font-semibold leading-none dark:text-white flex justify-between">
                    <span className="flex flex-wrap">{inbox.contactName}</span>
                    <span
                      onClick={e=>onDeleteButtonClick(inbox._id)}
                      className="cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 32 32"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </span>
                  </p>
                  <p className="text-xs font-semibold leading-none dark:text-white overflow-wrap break-words">
                    {inbox.contactSubject}
                  </p>
                  <p className="text-xs font-semibold leading-none dark:text-white overflow-wrap break-words">
                    {inbox.contactEmail},{inbox.contactNumber}
                  </p>
                  <p className="text-xs leading-none mt-1 text-gray-600 dark:text-white">
                   {
                    inbox.contactMessage
                   }
                  </p>

                  <p className="text-xs leading-3 pt-1 text-gray-500 dark:text-white">
                    {
                      calculateTimeGap(inbox.contactDate) 
                    }
                    { ` ago`}
                  </p>
                </div>
              </div>
            );
          })}

        <div className="flex border-t  items-center justiyf-between dark:bg-gray-800">
          <hr className="w-full" />
          <p className="text-sm flex flex-shrink-0 leading-normal px-3 py-12 text-gray-500 dark:bg-gray-800 dark:text-white">
           (Thats it No more Queries:)
          </p>
          <hr className="w-full" />
        </div>
      </div>

      {/* confirmation delete  */}

      {deleteModalState && (
        <ConfrimationModal
          handleConfirm={confirmDelete}
          handleCancel={cancelDelete}
          confirmationMessage={"Are you Sure?Do You want to delete is message?"}
        />
      )}
    </>
  );
}

export default Inbox;
