import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import noImage from "../../assests/noImage.png";
// import "./styles.css";

function MultipleImageUpload(props) {
  //   const [images, setImages] = React.useState([]);
  const { images, setImages } = props;
  const maxNumber = 69;

  const onImageRemoveAll=()=>{
    setImages([]);
  }
  const onChangeHandle = (e) => {
    console.log(e.target.name);
    if (e.target.name === "productImages") {
      const file = e.target.files[0];
      //  resizeFile(file).then(image=>{
      //    console.log(image);
      //    setAvatarPreview(image);
      //    setAvatar(image);
      //  });
      // console.log(file);
      if(file.type=="image/jpeg"||file.type=="image/webp" || file.type=="image/png" ){
       
        setImages([...images,...e.target.files]);
        // const url=URL.createObjectURL(file);
        // setImagePreview(url);
      }else{
        // setImagePreview(noImageLogo);
      }

      
      
    } 
    // console.log(e.target);
    // data for submit
    // console.log(imageList, addUpdateIndex);
    // setImages(e.target.);
  };

  const onImageRemove = (index) => {
    console.log(index);
  let newImages=  images.slice(0, index).concat(images.slice(index + 1));
    setImages(newImages);
  };

  const onImageUpdate = () => {};
  useEffect(() => {
    console.log(images);
  }, [images]);
  return (
    <>
      <div>
        {images.length == 0 ? (
          <div
            style={{ minHeight: "100px" }}
            className="flex w-full justify-center items-center"
          >
            <div>
              <img
                src={noImage}
                alt="no image"
                style={{ height: "80px", width: "80px", objectFit: "contain" }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{ minHeight: "100px" }}
            className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2"
          >
            {images && images.map((image, index) => (
              <div key={index}>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                  }}
                />
                <div className="flex  flex-wrap justify-between pl-1 pr-6 pt-2">
                  {/* <div
                    onClick={() => onImageUpdate(index)}
                    className="pl-2 pr-2 pt-1 pb-1 cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Update
                  </div> */}
                  <div
                    onClick={() => onImageRemove(index)}
                    className="pl-2 pr-2 pt-1 pb-1 cursor-pointer text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div class="flex items-center justify-center w-full">
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              class="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
           
           
          </div>
          <input
            onChange={onChangeHandle}
            id="dropzone-file"
            name="productImages"
            type="file"
            class="hidden"
            multiple
          />
          
            

        
          {/* <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="files"
                type="file"
                name="productImages"
                // value={siteSettingData?.siteLogo?siteSettingData.siteLogo:""}
                onChange={onChangeHandle}
              /> */}
        </label>
      </div>

      <div className="w-full flex items-center justify-center">
      {
              images.length>0?     <div
              className="p-2 m-3 font-semibold dark:bg-gray-900 w-max text-sm text-white border-sm border-gray-300 rounded-lg cursor-pointer bg-gray-900 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"

             onClick={onImageRemoveAll}
             >Remove all images
             </div>:<></>
            }
      </div>
      
       
    </>
  );
}

export default MultipleImageUpload;

//  <div>
//       <ImageUploading
//         multiple
//         value={images}
//         onChange={onChange}
//         maxNumber={maxNumber}
//         dataURLKey="data_url"
//         acceptType={["jpg"]}

//       >
//         {({
//           imageList,
//           onImageUpload,
//           onImageRemoveAll,
//           onImageUpdate,
//           onImageRemove,
//           isDragging,
//           dragProps,
//         }) => (
//           // write your building UI
//           <div
//             // style={{
//             //   background:`{${images.length==0?`url(${noImage})`:""}}`,
//             // //   minHeight: "200px",
//             //   minHeight: "100px", // set your desired height
//             // //   width: "300px",

//             //   backgroundRepeat: "no-repeat",
//             //   backgroundPosition: "center",
//             //   backgroundSize: "contain",

//             // }}
//             className=" bg-gray-50  dark:bg-gray-700"
//           >
//             {/* display images */}
//             {
//                 images.length==0? <div
//                 style={{minHeight:"100px"}}
//                 className="flex w-full justify-center items-center"><div>
//                     <img src={noImage} alt="no image" style={{height:"80px",width:"80px",objectFit:"contain"}} />

//                 </div>
//                 </div>: <div
//             style={{minHeight:"100px"}}
//              className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2">

//              {imageList.map((image, index) => (
//               <div key={index} >
//                 <img src={image.data_url} alt=""  style={{
//                     height:"200px",
//                     width:"200px",
//                     objectFit:"cover"

//                 }} />
//                 <div className="flex  flex-wrap justify-between pl-1 pr-6 pt-2">
//                   <div onClick={() => onImageUpdate(index)}
//             className="pl-2 pr-2 pt-1 pb-1 cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//                   >Update</div>
//                   <div onClick={() => onImageRemove(index)}
//             className="pl-2 pr-2 pt-1 pb-1 cursor-pointer text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"

//                   >Remove</div>
//                 </div>
//               </div>
//             ))}
//              </div>
//             }

//             <div className="flex mt-4 w-full flex-col sm:flex-row justify-center items-center gap-2 ">
//             <div
//               className="p-2 font-semibold w-max text-sm text-white border-sm border-gray-300 rounded-lg cursor-pointer bg-gray-900 dark:text-gray-400 focus:outline-none dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400"
//               style={isDragging ? { color: "red" } : null}
//               onClick={onImageUpload}
//               {...dragProps}
//             >
//               Click or Drop here
//             </div>
//             <div
//               className="p-2 font-semibold dark:bg-gray-900 w-max text-sm text-white border-sm border-gray-300 rounded-lg cursor-pointer bg-gray-900 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"

//              onClick={onImageRemoveAll}>Remove all images</div>
//             </div>

//           </div>
//         )}
//       </ImageUploading>
//     </div>
