import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getBanner = createAsyncThunk(
    "banner/getClientBanner",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/banner`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all banner!");
        }
    })

const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        banner:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getBanner.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getBanner.fulfilled, (state, action) => {
            state.banner=action.payload
            state.status = 'idle'
          }) .addCase(getBanner.rejected, (state, action) => {
            // state.banner=action.payload
            state.status = 'failed'
          })
      }
    
})
export default bannerSlice;

// extraReducers: {
//     [getBanner.fulfilled]: (state, { payload }) => {
//         state.banner = payload;
//     },
// }