import React from "react";

const Item = ({ Links, title }) => {
  console.log(Links);
  return (
    <ul>
      <h1 className="mb-1 text-teal-400 font-semibold ">{title}</h1>
      {Links.map((link,index) => {
        return (
          <li key={index}>
            <div
              className=" hover:text-teal-400 duration-300
          text-sm cursor-pointer leading-6"
              // href={link.link}
            >
              {link.name}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Item;
