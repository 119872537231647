import { useEffect, useState } from "react";

import Select from "react-select";
import demoProductImage from "../../demoImages/productDemoImage1.jpg";
import CKeditor from "../ckEditor/ckEditor";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import MultipleImageUpload from "../multipleImageUpload/multipleImageUpload";
import "../../styles/global.css";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@material-tailwind/react";
import useCustomNotification from "../../utils/notification";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import { getProduct, postProduct } from "../../store/admin/product";
const categoryList = [
  { value: "Electronics", label: "Electronics" },
  { value: "Cameras", label: "Cameras" },
  { value: "Laptops", label: "Laptops" },
  { value: "Accessories", label: "Accessories" },
  { value: "Headphones", label: "Headphones" },
  { value: "Food", label: "Food" },
  { value: "Books", label: "Books" },
  { value: "Clothes/Shoes", label: "clothes/Shooes" },
  { value: "Beauty/Health", label: "Beauty/Health" },
  { value: "Sports", label: "Sports" },
  { value: "Outdoor", label: "Outdoor" },
  { value: "Home", label: "Home" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "w-full",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};
let productDescriptionArray = [1, 6];

function CreateProduct() {
  const notification = useCustomNotification();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(getProduct({notification}))

  },[])
  const {status,product}=useSelector(state=>state.adminProduct);
  const[name,setName]=useState("")
  const [category,setCategory] = useState("");
  const[selectedProductId,setSelectedProductId]=useState("undefined");
    //   for multiple image upload
    const [uploadImages, setUploadImages] = useState([]);
    const [dataCkEditor, setDataCkEditor] = useState("");

  const handleOnchange = (val) => {
    setCategory(val);
    // console.log(vategory);
  };

  const options = [
    { label: "Design", value: "Design" },
    { label: "Building", value: "Building" },
    { label: "Kitchen", value: "Kitchen" },
    { label: "Architect", value: "Architect" },
  ];

  const resetForm=()=>{
    setName("");
    setCategory("");
    setUploadImages([]);
    setDataCkEditor("");
  }



  const handleSubmitButton = (e) => {
    e.preventDefault();
    const formData=new FormData();

    formData.append('_id',selectedProductId);
    formData.append('productName',name);
    formData.append('productCategory',category);
    formData.append('productDescription',dataCkEditor);
    console.log(uploadImages);
for (let i = 0; i < uploadImages.length; i++) {
  // const element = uploadImages[i];
  formData.append('productImages',uploadImages[i]);

  
}
    dispatch(postProduct({formData,resetForm,notification}))

  };

  useEffect(() => {
    // setDataCkEditor(true);
  }, []);

  // // this is just for test
  // const changeDescriptionFieldNumber = (changeValue) => {
  //   setDescriptionFieldNo(descriptionFieldNo + changeValue);
  //   if (changeValue == 1) {
  //     productDescriptionArray.push(descriptionFieldNo);
  //   } else {
  //     productDescriptionArray.pop();
  //   }

  //   console.log("increse", descriptionFieldNo, productDescriptionArray);
  // };

  if (status == "loading") {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }



  return (
    <div>
      <div className="p-4 w-full  bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" action="#" onSubmit={handleSubmitButton}>
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Create Product
          </h5>
          <div className="w-11/12">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Product Name
            </label>
            <input
              type="text"
              name="text"
              value={name}
              onChange={e=>setName(e.target.value)}
              id="productName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="product name"
              required
            />
          </div>

          <div className="w-11/12">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Product Category
            </label>
            <MultiSelect
              className="multi-select"
              onChange={handleOnchange}
              options={options}
              customValue={true}
              
            />
          </div>

          <div className="w-11/12 ">
            <CKeditor
              name="Product Description "
              content={dataCkEditor}
              setContent={setDataCkEditor}
              initialHeight="150px"

              // editorLoaded={editorLoaded}
            />
            {/* {JSON.stringify(dataCkEditor)} */}
          </div>

          <div className="w-11/12">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
            Upload  Product Images
            </label>
            <MultipleImageUpload images={uploadImages} setImages={setUploadImages} />
          </div>

          <div className="w-full flex justify-center m-4">
            <button
              type="submit"
              className="w-content max-w-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProduct;
