import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProduct } from "../../store/admin/product";
import useCustomNotification from "../../utils/notification";
import ConfrimationModal from "../confirmationModal/confirmationModal";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import ProductsTable from "./Tables/productsTable";

function Products() {
    const notification = useCustomNotification();
    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(getProduct({notification}))
  
    },[])

    const [productDeleteId,setProductDeleteId]=useState(undefined);

    const {status,product}=useSelector(state=>state.adminProduct);
 
  
    const [deleteModalState, setDeleteModalState] = useState(false);
  
    const onHandleButtonClick = (id) => {
      setDeleteModalState(true);
      setProductDeleteId(id)
      console.log("dd");
    };

    const onHandleEditClick=()=>{

    }
  
    const confirmDelete = () => {
      console.log("delete confirm");
      const formData=new FormData();
      formData.append('_id',productDeleteId);
  
      dispatch(deleteProduct({formData,notification}));
      setProductDeleteId(undefined)
      setDeleteModalState(false);
    };
    const cancelDelete = () => {
      console.log("delete cancel");
      setDeleteModalState(false);
    };

    useEffect(() => {}, [deleteModalState,product]);

    if (status == "loading") {
        return (
          <>
            <LoadingSpinner />
          </>
        );
      }
    return ( <div>
        <ProductsTable
        list={product}
        handleEdit={onHandleEditClick}
        handleDelete={onHandleButtonClick}

        />
        {/* confirmation delete  */}

      {deleteModalState && (
        <ConfrimationModal
          handleConfirm={confirmDelete}
          handleCancel={cancelDelete}
          confirmationMessage={"Are you Sure?Do You want to delete this product?"}
        />
      )}
    </div> );
}

export default Products;