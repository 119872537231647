import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getQuestionAnswer = createAsyncThunk(
    "questionAnswer/getClientQuestionAnswer",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/client/questionanswer`);
            // console.log(response);
            if(response.data.sucess){
                return response.data.data;
            }else{
                return null;
            }
           
        }
        catch (err) {
            return rejectWithValue("Failed to get all questionAnswer!");
        }
    })

const questionAnswerSlice = createSlice({
    name: "questionAnswer",
    initialState: {
        questionAnswer:null,
    },
    extraReducers: builder => {
        builder
          .addCase(getQuestionAnswer.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(getQuestionAnswer.fulfilled, (state, action) => {
            state.questionAnswer=action.payload
            state.status = 'idle'
          }) .addCase(getQuestionAnswer.rejected, (state, action) => {
            // state.questionAnswer=action.payload
            state.status = 'failed'
          })
      }
    
})
export default questionAnswerSlice;

// extraReducers: {
//     [getQuestionAnswer.fulfilled]: (state, { payload }) => {
//         state.questionAnswer = payload;
//     },
// }